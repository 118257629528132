import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { ClustersService } from "../../clusters/clusters.service";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import { SharedService } from "../../../services/shared.service";
import { ErrorService } from "../../../components/error/error.service";

import { Source, BroadcasterInput, FeederInput, OutputNIC, InputNIC } from "../../../models/shared";
import { AmazonAWS, AmazonAWSMediaConnectFlow } from "../../configuration/amazon-aws/amazon-aws";
import { Cluster } from "../../clusters/cluster";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { UsersService } from "../../account-management/users/users.service";
import { ClipboardService } from "ngx-clipboard";
import { FeederInputPipe } from "src/app/pipes/feeder-input.pipe";
import { BroadcasterInputPipe } from "src/app/pipes/broadcaster-input.pipe";
import { ZecsService } from "../../zecs/zecs.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators, FormControl } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-source-form-zixi",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormZixiComponent implements OnInit {
    source: Source;
    private sources: Source[];
    sourceNames: string[];
    inputNICs: InputNIC[] = [];

    public existingSource: Source;
    newSources: Partial<Source>[];
    private action: string;
    loading = true;
    saving = false;
    isEdit = false;
    isClone = false;
    singleMode = true;
    startDisabled = false;
    targetNICLoading = false;
    savingMultiple: boolean;
    constants = Constants;

    set multiMode(v: boolean) {
        this.singleMode = !v;
    }
    get multiMode(): boolean {
        return !this.singleMode;
    }

    show_password = false;
    encrypt: number;
    bondingOption: number | boolean;
    application: "zec" | "feeder" | "broadcaster" | "mediaconnect" | "zixi_pull" | "other" = "other";
    feederInputId: string;
    broadcasterInputId: string;
    pullStreamId: string;
    selectedCluster: Cluster;

    awsAccounts: AmazonAWS[] = [];
    awsAccountRegionsLoading = false;
    awsRegions: { id: string; name: string }[] = [];
    mediaconnectFlowsLoading = false;
    mediaconnectFlows: AmazonAWSMediaConnectFlow[] = [];

    broadcasterDetailsLoading = false;
    broadcasterInputs: BroadcasterInput[] = [];
    selectedBroadcasterInputs: BroadcasterInput[] = [];
    broadcasterInputsFilter = "";

    broadcasterOutputsAll: OutputNIC[] = [{ name: "Any", value: "", nic: "" }];
    broadcasterOutputsFilter: string;
    broadcasterOutputNICs: OutputNIC[] = [];
    selectedBroadcasterOutputNICs: OutputNIC[] = [];

    feederDetailsLoading = false;
    feederInputs: FeederInput[] = [];
    selectedFeederInputs: FeederInput[] = [];
    feederInputsFilter = "";

    feederOutputsAll: OutputNIC[] = [{ name: "Any", value: "", nic: "" }];
    feederOutputsFilter: string;
    feederOutputNICs: OutputNIC[] = [];
    selectedFeederOutputNICs: OutputNIC[] = [];

    maxBitrates = Constants.maxBitrates;

    page = 1;
    pageSize = 10;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private ss = inject(SourcesService);
    private broadcastersService = inject(BroadcastersService);
    private clusterService = inject(ClustersService);
    private feederService = inject(ZecsService);
    private zecService = inject(ZecsService);
    private sharedService = inject(SharedService);
    private aas = inject(AmazonAwsService);
    private errorService = inject(ErrorService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private userService = inject(UsersService);
    private cbs = inject(ClipboardService);
    private feederInputPipe = inject(FeederInputPipe);
    private broadcasterInputPipe = inject(BroadcasterInputPipe);
    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    private targetBXsBase = this.targetBXs;

    inputMode: "push" | "pull" = "push";

    MAX_INPUTS = 25;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    ingestControl = new UntypedFormControl("", [Validators.required]);
    minimizeLatencyControl = new FormControl<boolean>(false);

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    generatePassword() {
        this.source.password = this.sharedService.generateStrongPassword();
    }

    private prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.singleMode = true;
                this.nameControl.setValue(this.source.name);
                this.ingestControl.setValue(this.source.broadcaster_cluster_id);
                this.minimizeLatencyControl.setValue(this.source.minimize_latency || false);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.singleMode = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                this.encrypt = this.source.encryption === "auto" ? 1 : 0;
                this.inputMode =
                    this.source.mediaconnect_mode === "pull" || this.source.type === "zixi_pull" ? "pull" : "push";

                if (this.source.broadcaster_cluster_id) {
                    this.clusterSelectionChange(this.source.broadcaster_cluster_id, false);
                }

                if (this.source.feeder_id) {
                    this.getFeederDetails(this.source.feeder_id);
                    this.application = "feeder";
                    this.feederInputId = this.source.input_id;
                }

                if (this.source.broadcaster_id) {
                    this.getBroadcasterDetails(this.source.broadcaster_id);
                    this.application = "broadcaster";
                    this.broadcasterInputId = this.source.input_id;
                }

                if (this.source.zec_id) {
                    this.getZecDetails(this.source.zec_id);
                    this.application = "zec";
                    this.broadcasterInputId = this.source.input_id;
                }

                if (this.source.aws_account_id) {
                    this.getAWSAccountRegions(this.source.aws_account_id);
                    if (this.source.region)
                        this.getAWSMediaConnectFlows(this.source.aws_account_id, this.source.region);
                    this.application = "mediaconnect";
                } else {
                    if (this.inputMode === "pull") {
                        this.application = "zixi_pull";
                        this.pullStreamId = this.source.input_id;
                    }
                }

                this.source.output_nic = this.source.output_nic || "";

                // Bonding
                if (this.source.bondedLinks && this.source.bondedLinks.length !== 0) {
                    this.bondingOption = 1;
                }
                if (this.source.autobond === 1) {
                    this.bondingOption = 2;
                } else {
                    if (this.source.bondedLinks && this.source.bondedLinks.length !== 0) {
                        this.bondingOption = 1;
                    } else {
                        this.bondingOption = 0;
                    }
                }

                if (this.source.target_broadcaster_id > 0) {
                    this.getTargetBroadcasterDetails(this.source.target_broadcaster_id);
                }
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.application = "zec";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    resetForm() {
        // Source
        this.source.mediaconnect_mode = "pull";
        this.source.latency = 4000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.output_nic = "";
        this.source.transcode_source_id = null;
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.transcode_profile_id = null;
        this.source.transcode_cbr_kbps = null;
        this.source.protocol = null;
        this.source.remote_host = "";
        this.source.remote_port = 2088;
        this.source.input_nic = null;
        this.source.pid_mapping_profile_id = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        // this.source.webrtc_thumbnail = 0;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.mtu = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.source.minimize_latency = false;
        this.source.priority_client_id = null;
        // UI
        this.multiMode = false;
        this.bondingOption = 0;
        this.minimizeLatencyControl.reset();
    }

    async clusterSelectionChange(id: number, clusterChanged: boolean) {
        //  Clear specific broadcser selection since cluster is about to change.
        if (clusterChanged && this.source && this.source.target_broadcaster_id >= 0)
            this.source.target_broadcaster_id = undefined;

        this.getClusterSourceNames(id);
        this.getTargetBroadcasters(id);
        this.getCluster(id);

        this.ingestControl.setValue(id);
    }

    private getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    getCluster(id: number) {
        this.selectedCluster = this.clusterService.getCachedCluster(null, id);
    }

    async getAWSAccountRegions(id: number) {
        this.awsRegions = [];
        this.awsAccountRegionsLoading = true;
        if (!id) {
            this.awsAccountRegionsLoading = false;
            return;
        }

        const regions = await this.aas.getAWSAccountRegions(id);
        if (regions) {
            const awsRegions = [];
            Object.entries(regions).forEach(([key, value]) => {
                awsRegions.push({ id: key, name: value });
            });
            this.awsRegions = awsRegions;
        }
        this.awsAccountRegionsLoading = false;
    }

    async getAWSMediaConnectFlows(id: number, region: string) {
        this.mediaconnectFlows = [];
        this.mediaconnectFlowsLoading = true;
        if (!id) {
            this.mediaconnectFlowsLoading = false;
            return;
        }

        const flows = await this.aas.getAWSAccountMediaConnectFlows(id, region);
        if (flows) this.mediaconnectFlows = flows;

        this.mediaconnectFlowsLoading = false;
    }

    private async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;

        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }

        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = this.targetBXsBase.concat(
                _.map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }

        this.targetBXsLoading = false;
    }

    async getTargetBroadcasterDetails(id: number) {
        this.targetNICLoading = true;
        this.inputNICs = [];

        const broadcaster =
            id > 0 ? await this.broadcastersService.refreshBroadcaster(id, true).toPromise() : undefined;
        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.nics) {
                this.inputNICs = broadcaster.status.nics || [{ name: "Any", value: "", nic: "" }];
                if (!this.source.input_nic) this.source.input_nic = "";
            } else {
                this.inputNICs = [{ name: "Any", value: "", nic: "" }];
                this.source.input_nic = "";
            }
        } else {
            this.inputNICs = [{ name: "Any", value: "", nic: "" }];
            this.source.input_nic = "";
        }
        this.targetNICLoading = false;
    }

    async getFeederDetails(id: number, clearConfig?: boolean) {
        this.feederInputs = [];
        this.feederOutputsAll = [{ name: "Any", value: "", nic: "" }];
        this.feederOutputNICs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedFeederInputs = [];
            this.selectedFeederOutputNICs = [];
        }

        this.feederDetailsLoading = true;
        if (!id) {
            this.feederDetailsLoading = false;
            return;
        }

        const feeder = await this.feederService.refreshZec(id, "FEEDER", true).toPromise();
        if (feeder && feeder.status) {
            if (feeder.status.inputs) {
                this.feederInputs = this.sharedService.sort(
                    (feeder.status.inputs as FeederInput[]) || [],
                    "name",
                    "asc"
                );
            } else {
                this.feederInputs = [];
            }
            if (feeder.status.nics) {
                this.feederOutputsAll = feeder.status.nics || [{ name: "Any", value: "", nic: "" }];
                this.feederOutputNICs = feeder.status.nics || [];
                if (!feeder.status.nics || feeder.status.nics.length === 0) this.source.output_nic = "";
            } else {
                this.feederOutputNICs = [];
                this.source.output_nic = "";
            }
        } else {
            this.feederInputs = [];
            this.feederOutputNICs = [];
            this.source.output_nic = "";
        }

        // reset selected input if it no longer exists on the fx
        if (!this.feederInputs.find(input => input.name === this.feederInputId)) this.feederInputId = null;

        if (!clearConfig && this.bondingOption === 1) {
            if (this.application === "feeder")
                this.source.bondedLinks
                    ?.map(l =>
                        _.extend({}, l, {
                            max_bitrate: l.max_bitrate / 1000,
                            name: l.nic_ip,
                            nic: l.nic_ip
                        })
                    )
                    .forEach(l => this.selectFeederOutputNIC(l));
        }

        this.feederDetailsLoading = false;
    }

    async getBroadcasterDetails(id: number, clearConfig?: boolean) {
        this.broadcasterInputs = [];
        this.broadcasterOutputsAll = [{ name: "Any", value: "", nic: "" }];
        this.broadcasterOutputNICs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedBroadcasterInputs = [];
            this.selectedBroadcasterOutputNICs = [];
        }
        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }

        let broadcaster = null;
        try {
            broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();
        } catch (e) {
            this.source.broadcaster_id = undefined;
        }

        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.inputs) {
                this.broadcasterInputs = this.sharedService.sort(broadcaster.status.inputs || [], "id", "asc");
            } else {
                this.broadcasterInputs = [];
            }
            if (broadcaster.status.nics) {
                this.broadcasterOutputsAll = broadcaster.status.nics || [{ name: "Any", value: "", nic: "" }];
                this.broadcasterOutputNICs = broadcaster.status.nics || [];
                if (!broadcaster.status.nics || broadcaster.status.nics.length === 0) this.source.output_nic = "";
            } else {
                this.broadcasterOutputNICs = [];
                this.source.output_nic = "";
            }
        } else {
            this.broadcasterInputs = [];
            this.broadcasterOutputNICs = [];
            this.source.output_nic = "";
        }

        // reset selected input if it no longer exists on the bx
        if (!this.broadcasterInputs.find(input => input.id === this.broadcasterInputId)) this.broadcasterInputId = null;

        if (!clearConfig && this.bondingOption === 1) {
            if (this.application === "broadcaster")
                this.source.bondedLinks
                    ?.map(l =>
                        _.extend({}, l, {
                            max_bitrate: l.max_bitrate / 1000,
                            name: l.nic_ip,
                            nic: l.nic_ip
                        })
                    )
                    .forEach(l => this.selectBroadcasterOutputNIC(l));
        }

        this.broadcasterDetailsLoading = false;
    }

    async getZecDetails(id: number, clearConfig?: boolean) {
        this.broadcasterInputs = [];
        this.broadcasterOutputsAll = [{ name: "Any", value: "", nic: "" }];
        this.broadcasterOutputNICs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedBroadcasterInputs = [];
            this.selectedBroadcasterOutputNICs = [];
        }
        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }

        let zec = null;
        try {
            zec = await this.zecService.refreshZec(id, "ZEC", true).toPromise();
        } catch (e) {
            this.source.zec_id = undefined;
        }

        if (zec && zec.status) {
            if (zec.status.inputs) {
                this.broadcasterInputs = this.sharedService.sort(zec.status.inputs || [], "id", "asc");
            } else {
                this.broadcasterInputs = [];
            }
            if (zec.status.nics) {
                this.broadcasterOutputsAll = zec.status.nics || [{ name: "Any", value: "", nic: "" }];
                this.broadcasterOutputNICs = zec.status.nics || [];
                if (!zec.status.nics || zec.status.nics.length === 0) this.source.output_nic = "";
            } else {
                this.broadcasterOutputNICs = [];
                this.source.output_nic = "";
            }
        } else {
            this.broadcasterInputs = [];
            this.broadcasterOutputNICs = [];
            this.source.output_nic = "";
        }

        // reset selected input if it no longer exists on the bx
        if (!this.broadcasterInputs.find(input => input.id === this.broadcasterInputId)) this.broadcasterInputId = null;

        if (!clearConfig && this.bondingOption === 1) {
            if (this.application === "zec")
                this.source.bondedLinks
                    ?.map(l =>
                        _.extend({}, l, {
                            max_bitrate: l.max_bitrate / 1000,
                            name: l.nic_ip,
                            nic: l.nic_ip
                        })
                    )
                    .forEach(l => this.selectBroadcasterOutputNIC(l));
        }

        this.broadcasterDetailsLoading = false;
    }

    selectFeederInput(input: FeederInput) {
        if (this.selectedFeederInputs.length >= this.MAX_INPUTS) return;

        this.selectedFeederInputs.push(input);
        input.source_name = input.name;

        input.max_bitrate = (
            _.find(this.maxBitrates, mb => {
                return mb.value > input.bitrate;
            }) || _.last(this.maxBitrates)
        ).value;

        this.feederInputs = this.feederInputs.filter(i => {
            return i.name !== input.name;
        });
    }

    deselectFeederInput(input: FeederInput) {
        this.feederInputs.push(input);
        this.feederInputs = [...this.sharedService.sort(this.feederInputs || [], "name", "asc")];
        this.selectedFeederInputs = this.selectedFeederInputs.filter(i => {
            return i.name !== input.name;
        });
    }

    selectFeederOutputNIC(output: OutputNIC) {
        this.selectedFeederOutputNICs.push(Object.assign({ backup: output.backup || 0, nic_ip: output.value }, output));
        this.feederOutputNICs = this.feederOutputNICs.filter(o => {
            return o.name !== output.name;
        });
    }

    deselectFeederOutputNIC(output: OutputNIC) {
        this.feederOutputNICs.push(output);
        this.feederOutputNICs = this.sharedService.sort(this.feederOutputNICs || [], "name", "asc");
        this.selectedFeederOutputNICs = this.selectedFeederOutputNICs.filter(o => {
            return o.name !== output.name;
        });
    }

    selectBroadcasterOutputNIC(output: OutputNIC) {
        this.selectedBroadcasterOutputNICs.push(
            Object.assign({ backup: output.backup || 0, nic_ip: output.value }, output)
        );
        this.broadcasterOutputNICs = this.broadcasterOutputNICs.filter(o => {
            return o.nic !== output.nic;
        });
    }

    deselectBroadcasterOutputNIC(output: OutputNIC) {
        this.broadcasterOutputNICs.push(output);
        this.broadcasterOutputNICs = this.sharedService.sort(this.broadcasterOutputNICs || [], "name", "asc");
        this.selectedBroadcasterOutputNICs = this.selectedBroadcasterOutputNICs.filter(o => {
            return o.nic !== output.nic;
        });
    }

    selectBroadcasterInput(input: BroadcasterInput) {
        if (this.selectedBroadcasterInputs.length >= this.MAX_INPUTS) return;

        this.selectedBroadcasterInputs.push(input);
        this.broadcasterInputs = this.broadcasterInputs.filter(i => {
            return i.id !== input.id;
        });
    }

    deselectBroadcasterInput(input: BroadcasterInput) {
        this.broadcasterInputs.push(input);
        this.broadcasterInputs = this.sharedService.sort(this.broadcasterInputs || [], "id", "asc");
        this.selectedBroadcasterInputs = this.selectedBroadcasterInputs.filter(i => {
            return i.id !== input.id;
        });
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.action = params.action;
        const sourceName = params.name;
        const sourceId = urlBuilder.decode(params.sourceId);

        await firstValueFrom(this.ss.refreshSources(true));
        this.sources = await firstValueFrom(this.ss.sources);
        if (sourceName && sourceId) {
            this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));

            // Check if source found in cache, if not get sources and source
            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                await firstValueFrom(this.ss.refreshSource(this.source));
                this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));
            }
            this.existingSource = _.cloneDeep(this.source);
        }

        // AWS Accounts
        this.awsAccounts = await firstValueFrom(this.aas.refreshAWSAccounts(true));
        this.prepForm();

        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;
        let newSources: Record<string, unknown>[] = [];

        if (this.bondingOption === true) this.bondingOption = 1;

        if (!this.singleMode && this.application === "feeder") {
            if (this.bondingOption === 1 && this.selectedFeederOutputNICs.length === 0) {
                this.saving = false;
                return;
            }

            newSources = _.map(this.selectedFeederInputs, input => {
                return {
                    name: this.isEdit ? undefined : this.nameControl.value,
                    broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                    target_broadcaster_id: this.source.target_broadcaster_id,
                    feeder_id: this.source.feeder_id,
                    autobond: this.bondingOption === 2 ? 1 : 0,
                    broadcaster_id: null,
                    input_id: input.name,
                    max_bitrate: input.max_bitrate,
                    latency: this.source.latency,
                    monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                    content_analysis: this.source.content_analysis ? 1 : 0,
                    tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                    monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
                    monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
                    resource_tag_ids: _.map(this.tagsControl.value, "id"),
                    alerting_profile_id: this.source.alertingProfile.id,
                    password: this.source.password || "",
                    encryption: this.source.encrypt ? "auto" : "none",
                    encryption_key: "",
                    allow_outputs: this.source.allow_outputs ? 1 : 0,
                    outputs_password: this.source.outputs_password || "",
                    cleanup_outputs: this.source.cleanup_outputs ? 1 : 0,
                    output_nic: this.source.output_nic || "",
                    pid_mapping_profile_id: this.source.pid_mapping_profile_id,
                    bondedLinks:
                        this.bondingOption === 1
                            ? _.map(this.selectedFeederOutputNICs || [], output => {
                                  return _.extend({}, output, { max_bitrate: output.max_bitrate * 1000 });
                              })
                            : [],
                    report_scte_warnings: this.source.report_scte_warnings,
                    process_scte_reports: this.source.process_scte_reports,
                    location: this.source.location.address,
                    muted: this.source.muted,
                    is_enabled:
                        !this.isEdit && this.startDisabled
                            ? 0
                            : !this.isEdit && !this.startDisabled
                            ? 1
                            : this.source.is_enabled
                };
            });
        } else if (!this.singleMode && this.application === "broadcaster") {
            if (this.bondingOption === 1 && this.selectedBroadcasterOutputNICs.length === 0) {
                this.saving = false;
                return;
            }

            newSources = _.map(this.selectedBroadcasterInputs, input => {
                return {
                    name: this.isEdit ? undefined : this.nameControl.value,
                    broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                    target_broadcaster_id: this.source.target_broadcaster_id,
                    feeder_id: null,
                    autobond: 0,
                    broadcaster_id: this.source.broadcaster_id,
                    input_id: input.id,
                    max_bitrate: input.max_bitrate,
                    latency: this.source.latency,
                    monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                    content_analysis: this.source.content_analysis ? 1 : 0,
                    tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                    monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
                    monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
                    resource_tag_ids: _.map(this.tagsControl.value, "id"),
                    alerting_profile_id: this.source.alertingProfile.id,
                    password: this.source.password || "",
                    encryption: this.source.encrypt ? "auto" : "none",
                    encryption_key: "",
                    allow_outputs: this.source.allow_outputs ? 1 : 0,
                    outputs_password: this.source.outputs_password || "",
                    cleanup_outputs: this.source.cleanup_outputs ? 1 : 0,
                    output_nic: this.source.output_nic || "",
                    pid_mapping_profile_id: this.source.pid_mapping_profile_id,
                    bondedLinks:
                        this.bondingOption === 1
                            ? _.map(this.selectedBroadcasterOutputNICs || [], output => {
                                  return _.extend({}, output, { max_bitrate: output.max_bitrate * 1000 });
                              })
                            : [],
                    report_scte_warnings: this.source.report_scte_warnings,
                    process_scte_reports: this.source.process_scte_reports,
                    location: this.source.location.address,
                    muted: this.source.muted,
                    is_enabled:
                        !this.isEdit && this.startDisabled
                            ? 0
                            : !this.isEdit && !this.startDisabled
                            ? 1
                            : this.source.is_enabled,
                    input_nic: this.source.input_nic && this.source.input_nic !== "" ? this.source.input_nic : null,
                    traffic_shaping_kbps: this.source.traffic_shaping_kbps,
                    max_fec_overhead_percent: this.source.max_fec_overhead_percent,
                    minimize_latency: this.source.minimize_latency ? 1 : 0,
                    frame_thinning_latency: this.source.frame_thinning_latency
                };
            });
        } else if (this.application === "mediaconnect") {
            newSources.push({
                name: this.isEdit ? undefined : this.nameControl.value,
                broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                feeder_id: null,
                broadcaster_id: null,
                target_broadcaster_id: this.source.target_broadcaster_id,
                input_id: null,
                max_bitrate: 100000000,
                latency: this.source.latency,
                monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                content_analysis: this.source.content_analysis ? 1 : 0,
                tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
                monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.source.alertingProfile.id,
                password: this.source.password || "",
                encryption: "none",
                encryption_key: "",
                allow_outputs: this.source.allow_outputs ? 1 : 0,
                outputs_password: this.source.outputs_password || "",
                mediaconnect_flow_arn: this.source.mediaconnect_flow_arn,
                region: this.source.region,
                aws_account_id: this.source.aws_account_id,
                mediaconnect_mode: this.inputMode,
                pid_mapping_profile_id: this.source.pid_mapping_profile_id,
                report_scte_warnings: this.source.report_scte_warnings,
                process_scte_reports: this.source.process_scte_reports,
                location: this.source.location.address,
                muted: this.source.muted,
                is_enabled:
                    !this.isEdit && this.startDisabled
                        ? 0
                        : !this.isEdit && !this.startDisabled
                        ? 1
                        : this.source.is_enabled
            });
        } else if (this.application === "zixi_pull") {
            newSources.push({
                name: this.isEdit ? undefined : this.nameControl.value,
                broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                feeder_id: null,
                broadcaster_id: null,
                target_broadcaster_id: this.source.target_broadcaster_id,
                input_id: this.pullStreamId,
                latency: this.source.latency,
                monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                content_analysis: this.source.content_analysis ? 1 : 0,
                tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
                monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.source.alertingProfile.id,
                password: _.isEmpty(this.source.password) ? null : this.source.password,
                allow_outputs: this.source.allow_outputs ? 1 : 0,
                outputs_password: this.source.outputs_password || "",
                remote_host: this.source.remote_host,
                remote_port: this.source.remote_port,
                protocol: "zixi_pull",
                pid_mapping_profile_id: this.source.pid_mapping_profile_id,
                report_scte_warnings: this.source.report_scte_warnings,
                process_scte_reports: this.source.process_scte_reports,
                location: this.source.location.address,
                decryption_key: this.source.decryption_key,
                mediaconnect_flow_arn: null,
                region: null,
                aws_account_id: null,
                muted: this.source.muted,
                is_enabled:
                    !this.isEdit && this.startDisabled
                        ? 0
                        : !this.isEdit && !this.startDisabled
                        ? 1
                        : this.source.is_enabled,
                input_nic: this.source.input_nic && this.source.input_nic !== "" ? this.source.input_nic : null,
                bind_input_device: this.source.bind_input_device ? 1 : 0,
                minimize_latency: this.source.minimize_latency ? 1 : 0,
                traffic_shaping_kbps: this.source.traffic_shaping_kbps,
                max_fec_overhead_percent: this.source.max_fec_overhead_percent,
                frame_thinning_latency: this.source.frame_thinning_latency
            });
        } else {
            if (this.bondingOption === 1) {
                if (
                    (this.application === "feeder" && this.selectedFeederOutputNICs.length === 0) ||
                    (this.application === "broadcaster" && this.selectedBroadcasterOutputNICs.length === 0) ||
                    (this.application === "zec" && this.selectedBroadcasterOutputNICs.length === 0)
                ) {
                    this.saving = false;
                    return;
                }
            }

            newSources.push({
                name: this.isEdit ? undefined : this.nameControl.value,
                broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                autobond: this.application === "feeder" && this.bondingOption === 2 ? 1 : 0,
                feeder_id:
                    this.application === "feeder" && this.source.feeder_id && this.source.feeder_id !== -1
                        ? this.source.feeder_id
                        : null,
                broadcaster_id:
                    this.application === "broadcaster" &&
                    this.source.broadcaster_id &&
                    this.source.broadcaster_id !== -1
                        ? this.source.broadcaster_id
                        : null,
                zec_id:
                    this.application === "zec" && this.source.zec_id && this.source.zec_id !== -1
                        ? this.source.zec_id
                        : null,
                target_broadcaster_id: this.source.target_broadcaster_id,
                input_id:
                    this.application === "feeder"
                        ? this.feederInputId
                        : this.application === "broadcaster" || this.application === "zec"
                        ? this.broadcasterInputId
                        : null,
                max_bitrate: this.source.max_bitrate,
                latency: this.source.latency,
                monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                content_analysis: this.source.content_analysis ? 1 : 0,
                tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
                monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.source.alertingProfile.id,
                password: this.source.password || "",
                encryption: this.encrypt ? "auto" : "none",
                encryption_key: "",
                allow_outputs: this.source.allow_outputs ? 1 : 0,
                outputs_password: this.source.outputs_password || "",
                output_nic: this.bondingOption === 0 && this.source.output_nic ? this.source.output_nic : "",
                pid_mapping_profile_id: this.source.pid_mapping_profile_id,
                type:
                    this.application !== "broadcaster" && this.application !== "zec" && this.application !== "feeder"
                        ? "other"
                        : undefined, // TODO: get rid of this horrible hack, this should be determined in the backend based on source configuration
                bondedLinks:
                    this.bondingOption === 1
                        ? _.map(
                              (this.application === "feeder" && this.selectedFeederOutputNICs) ||
                                  ((this.application === "broadcaster" || this.application === "zec") &&
                                      this.selectedBroadcasterOutputNICs) ||
                                  [],
                              output => {
                                  const bondedLink = _.pick(output, ["nic_ip", "device", "max_bitrate", "backup"]);
                                  bondedLink.max_bitrate = bondedLink.max_bitrate * 1000;

                                  return bondedLink;
                              }
                          )
                        : [],
                report_scte_warnings: this.source.report_scte_warnings,
                process_scte_reports: this.source.process_scte_reports,
                location: this.source.location.address,
                decryption_key: this.source.decryption_key,
                mediaconnect_flow_arn: null,
                region: null,
                aws_account_id: null,
                muted: this.source.muted,
                is_enabled:
                    !this.isEdit && this.startDisabled
                        ? 0
                        : !this.isEdit && !this.startDisabled
                        ? 1
                        : this.source.is_enabled,
                priority_client_id: this.source.priority_client_id,
                traffic_shaping_kbps: this.source.traffic_shaping_kbps,
                max_fec_overhead_percent: this.source.max_fec_overhead_percent,
                minimize_latency: this.source.minimize_latency ? 1 : 0,
                frame_thinning_latency: this.source.frame_thinning_latency
            });
        }

        newSources.forEach(s => {
            s.disable_autopull = this.source.disable_autopull;
            s.autopull_latency = this.source.autopull_latency;
            s.webrtc_mode = this.source.webrtc_mode;
            s.mtu = this.source.mtu;
            s.autopull_mtu = this.source.autopull_mtu;
            s.billing_code = this.source.billing_code;
            s.billing_password = this.source.billing_password;
            s.autopull_billing_code = this.source.autopull_billing_code;
            s.autopull_billing_password = this.source.autopull_billing_password;
            s.freeze_detection_timeout_sec = this.source.freeze_detection_timeout_sec;
            s.blank_detection_timeout_sec = this.source.blank_detection_timeout_sec;
            s.hide_thumbnail = this.source.hide_thumbnail;
            s.enable_scte35_insertion = this.source.enable_scte35_insertion;
        });

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const changedData = this.sharedService.getZixiObjDiff(newSources[0], this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart zixi source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update zixi source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            if (newSources.length === 1) {
                const result = await this.ss.addSource(newSources[0]);
                if (result) {
                    this.mixpanelService.sendEvent("create zixi source");
                    this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
                } else this.saving = false;
            } else {
                this.savingMultiple = true;
                this.newSources = newSources;

                await Promise.all(
                    this.newSources.map(async newSource => {
                        const result = await this.ss.addSource(newSource);
                        if (result) {
                            this.mixpanelService.sendEvent("create zixi source");
                            Object.assign(newSource, { _frontData: { finished: true, error: false } });
                        } else {
                            const errorResponse = await firstValueFrom(this.errorService.currentHttpErrorResponse);
                            Object.assign(newSource, {
                                _frontData: { error: true, errorMsg: errorResponse.error.error, finished: true }
                            });
                        }
                    })
                );

                this.saving = false;
            }
        }
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    done() {
        this.router.navigate([Constants.urls.sources]);
    }

    inputModeChange() {
        if (this.inputMode === "push") {
            this.application = "zec";
        } else {
            this.application = "mediaconnect";
        }
        this.multiMode = false;
    }

    getFeederInputs(inputs: FeederInput[], filter = "") {
        return (inputs || []).filter(i => {
            return (i.name || "").toLowerCase().includes(filter.toLowerCase());
        });
    }

    getBroadcasterInputs(inputs: BroadcasterInput[], filter = "") {
        return (inputs || []).filter(i => {
            return (i.id || "").toLowerCase().includes(filter.toLowerCase());
        });
    }

    getOutputNics(outputs: OutputNIC[], filter = "") {
        return (outputs || []).filter(i => {
            return (i.name || "").toLowerCase().includes(filter.toLowerCase());
        });
    }

    broadcasterInputSearch = (term = "", item: BroadcasterInput) => {
        return this.broadcasterInputPipe.transform(item).toLowerCase().indexOf(term.toLowerCase()) > -1;
    };

    feederInputSearch = (term = "", item: FeederInput) => {
        return this.feederInputPipe.transform(item).toLowerCase().indexOf(term.toLowerCase()) > -1;
    };
}
