<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <h4 translate>PREVIEW</h4>
        <app-source-thumbnail
            [source]="source"
            [info]="false"
            [bordered]="true"
            [overlay]="showOverlay"
            [allowLivePlay]="true"
            [useService]="true"
        ></app-source-thumbnail>
        <div>
            <div class="d-flex justify-content-between">
                <div class="ellipsis">
                    <a
                        target="_blank"
                        href="https://console.aws.amazon.com/medialive/home?region={{ source.elemental_link_region }}#!/devices/{{ source.elemental_link_id }}"
                        ><strong>{{ source.elemental_link_id }}</strong></a
                    >
                </div>
                <div class="whitespace-nowrap" *ngIf="source.awsData">
                    <fa-icon
                        [icon]="source.awsData.ConnectionState | statusIcon"
                        class="status-{{ source.awsData.ConnectionState | statusClass }}"
                        class="me-1"
                    ></fa-icon
                    >{{ source.awsData.HdDeviceSettings.DeviceState }}
                </div>
            </div>
        </div>
    </div>
    <div class="flex-1 flex-basis-min">
        <div class="d-flex">
            <h4 translate>STREAM_INFORMATION</h4>
            <div class="form-group flex-shrink-0 mb-0 ms-auto">
                <div class="form-check">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="expand_programs"
                        name="expand_programs"
                        [(ngModel)]="expandPrograms"
                        (ngModelChange)="saveExpandPrograms()"
                    />
                    <label class="form-check-label" for="expand_programs">{{ "START_EXPANDED" | translate }}</label>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <app-mc-source-stream-info [source]="source" identifier="newpage" [expandPrograms]="expandPrograms"></app-mc-source-stream-info>
            </div>
        </div>
    </div>
</div>
