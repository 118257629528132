import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";

import { Source } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";
import { MultiviewSelectedSource } from "../../../components/shared/multiview-source-select/multiview-source-select.component";
import { MultiViewerService } from "../../multi-viewers/multi-viewer.service";
import { TagLayoutType, TagOutputConfig } from "@zixi/tag-vs";
import { UsersService } from "../../account-management/users/users.service";

type LayoutType = TagLayoutType["LayoutType"];
type OutputConfig = TagOutputConfig["Encoder"];

@Component({
    selector: "app-source-form-multiview",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormMultiviewComponent implements OnInit {
    source: Source;
    private sources: Source[];
    sourceNames: string[];
    private existingSource: Source;
    transcodeProfile: TranscodingProfile;

    private action: string;
    loading = true;
    saving = false;

    isEdit = false;
    isClone = false;
    private singleMode = true;
    startDisabled = false;
    multiViewerLoading = false;
    multiviewSources: MultiviewSelectedSource[] = [];
    constants = Constants;
    layoutTypes: LayoutType[];
    selectedLayoutType: LayoutType | null = null;
    outputConfigs: OutputConfig[];
    private selectedOutputConfig: OutputConfig | null = null;

    set multiMode(v: boolean) {
        this.singleMode = !v;
    }
    get multiMode(): boolean {
        return !this.singleMode;
    }

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private ss = inject(SourcesService);
    private broadcastersService = inject(BroadcastersService);
    private userService = inject(UsersService);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private multiViewersService = inject(MultiViewerService);

    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    private targetBXsBase = this.targetBXs;

    canZixiMultiview: boolean;
    canTagMultiview: boolean;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    ingestControl = new UntypedFormControl("", [Validators.required]);
    multiViewerControl = new UntypedFormControl("", [Validators.required]);

    private prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.singleMode = true;
                this.nameControl.setValue(this.source.name);
                this.ingestControl.setValue(this.source.broadcaster_cluster_id);
                this.multiViewerControl.setValue(this.source.multiview_id);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.singleMode = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                if (
                    this.source.multiview_id &&
                    this.source.multiview_type === "tag-mcm-9000" &&
                    (this.source.multiview_tag_layout_id || this.source.multiview_tag_encoder_id)
                ) {
                    this.multiViewerSelectedChange().then(() => {
                        this.layoutTypeChanged(
                            this.layoutTypes.find(l => l.id === this.source.multiview_tag_layout_id)
                        );
                        this.outputConfigChanged(
                            this.outputConfigs.find(o => o.id === this.source.multiview_tag_encoder_id)
                        );
                    });
                }

                if (this.source.broadcaster_cluster_id) {
                    this.clusterSelectionChange(this.source.broadcaster_cluster_id, false);
                }

                this.source.output_nic = this.source.output_nic || "";
                this.transcodeProfile = this.source.transcodeProfile;

                if (this.source.target_broadcaster_id > 0) {
                    this.getTargetBroadcasterDetails(this.source.target_broadcaster_id);
                }

                this.multiviewSources = (this.source.multiviewSources ?? []).map(ms => ({
                    grid_x: ms.grid_x,
                    grid_y: ms.grid_y,
                    label: ms.label,
                    source: this.ss.getCachedSource(null, null, ms.source_id)
                }));
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    private resetForm() {
        // Source
        this.source.mediaconnect_mode = "pull";
        this.source.latency = 4000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.output_nic = "";
        this.source.transcode_source_id = null;
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.transcode_profile_id = null;
        this.source.transcode_cbr_kbps = null;
        this.source.protocol = null;
        this.source.remote_host = "";
        this.source.remote_port = 2088;
        this.source.input_nic = null;
        this.source.pid_mapping_profile_id = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        // this.source.webrtc_thumbnail = 0;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.mtu = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.source.multiview_width = 2;
        this.source.multiview_height = 2;
        this.source.multiview_type = "tag-mcm-9000";
        this.source.billing_code = null;
        this.source.billing_password = null;

        // UI
        this.multiMode = false;
    }

    async multiViewerSelectedChange() {
        this.multiViewerLoading = true;
        this.multiViewerControl.setValue(this.source.multiview_id);
        try {
            this.selectedLayoutType = null;
            this.selectedOutputConfig = null;
            this.layoutTypes = (await this.multiViewersService.listLayouts(this.source.multiview_id)).map(
                l => l.LayoutType
            );
            this.outputConfigs = (await this.multiViewersService.listEncoders(this.source.multiview_id)).map(
                e => e.Encoder
            );
        } catch (e) {
            this.layoutTypes = [];
            this.outputConfigs = [];
        }

        this.multiViewerLoading = false;
    }

    layoutTypeChanged(chosenLayoutType: LayoutType) {
        if (this.selectedLayoutType && this.selectedLayoutType.id !== chosenLayoutType?.id) this.multiviewSources = [];
        this.selectedLayoutType = chosenLayoutType;
        this.source.multiview_tag_layout_id = this.selectedLayoutType.id;

        const layoutBoxes = this.selectedLayoutType.LayoutTypeBoxes;
        const squareRoot = Math.sqrt(layoutBoxes.length);
        this.source.multiview_width = Math.ceil(squareRoot);

        if (Number.isInteger(squareRoot)) {
            this.source.multiview_height = squareRoot;
        } else {
            this.source.multiview_height = Math.ceil(layoutBoxes.length / this.source.multiview_width);
        }
    }

    outputConfigChanged(chosenOutputConfig: OutputConfig) {
        this.selectedOutputConfig = chosenOutputConfig;
        this.source.multiview_tag_encoder_id = this.selectedOutputConfig.id;
    }

    async clusterSelectionChange(id: number, clusterChanged: boolean) {
        //  Clear specific broadcser selection since cluster is about to change.
        if (clusterChanged && this.source && this.source.target_broadcaster_id >= 0)
            this.source.target_broadcaster_id = undefined;

        this.getClusterSourceNames(id);
        this.getTargetBroadcasters(id);

        this.ingestControl.setValue(id);
    }

    private getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    private async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;

        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }

        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = this.targetBXsBase.concat(
                _.map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }

        this.targetBXsLoading = false;
    }

    async getTargetBroadcasterDetails(id: number) {
        const broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();
        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.nics) {
                if (!this.source.input_nic) this.source.input_nic = "";
            } else {
                this.source.input_nic = "";
            }
        } else {
            this.source.input_nic = "";
        }
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const sourceName = params.name;
        const sourceId = urlBuilder.decode(params.sourceId);
        this.action = params.action;

        await firstValueFrom(this.ss.refreshSources(true));
        this.sources = await firstValueFrom(this.ss.sources);
        if (sourceName && sourceId) {
            this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));

            // Check if source found in cache, if not get sources and source
            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                await firstValueFrom(this.ss.refreshSource(this.source));
                this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));
            }
            this.existingSource = _.cloneDeep(this.source);
        }

        const user = await firstValueFrom(this.userService.user);
        this.canZixiMultiview = !!user.zixi_multiview_allowed;
        this.canTagMultiview = !!user.tag_multiview_allowed;

        this.prepForm();
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;
        let newSource = {
            name: this.isEdit ? undefined : this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            autobond: 0,
            feeder_id: null,
            broadcaster_id: null,
            zec_id: null,
            target_broadcaster_id: this.source.target_broadcaster_id,
            input_id: null,
            content_analysis: this.source.content_analysis ? 1 : 0,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
            monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            password: "",
            encryption: "none",
            encryption_key: "",
            allow_outputs: this.source.allow_outputs ? 1 : 0,
            outputs_password: this.source.outputs_password || "",
            pid_mapping_profile_id: this.source.pid_mapping_profile_id,
            type: "multiview",
            protocol: "multiview",
            location: this.source.location.address,
            muted: this.source.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.source.is_enabled,
            multiview_id: this.source.multiview_id,
            multiview_type: this.source.multiview_type,
            multiview_tag_layout_id: this.source.multiview_tag_layout_id,
            multiview_tag_encoder_id: this.source.multiview_tag_encoder_id,
            multiviewSources: this.multiviewSources.map(ms => ({
                source_id: ms.source.id,
                label: ms.label,
                grid_x: ms.grid_x,
                grid_y: ms.grid_y
            })),
            multiview_width: this.source.multiview_width,
            multiview_height: this.source.multiview_height,
            transcode_profile_id: this.transcodeProfile?.id,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            webrtc_mode: this.source.webrtc_mode,
            mtu: this.source.mtu,
            autopull_mtu: this.source.autopull_mtu,
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion
        };

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const changedData = this.sharedService.getZixiObjDiff(newSource, this.existingSource, ["multiviewSources"]);
            if (this.isMultiviewSourcesEdited(newSource)) {
                changedData.multiviewSources = newSource.multiviewSources;
            }
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart zixi source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update zixi source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(newSource);
            if (result) {
                this.mixpanelService.sendEvent("create multiview source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    private isMultiviewSourcesEdited(newSource) {
        if (!this.isEdit) {
            return false;
        }
        if (this.existingSource.multiviewSources.length !== newSource.multiviewSources.length) {
            return true;
        }
        const isSourceConfigsIdentical = newSourceConfigs => {
            const existingSourceWithIdenticalConfigs = this.existingSource.multiviewSources.find(
                existingSource =>
                    existingSource.source_id === newSourceConfigs.source_id &&
                    existingSource.grid_x === newSourceConfigs.grid_x &&
                    existingSource.grid_y === newSourceConfigs.grid_y
            );
            return Boolean(existingSourceWithIdenticalConfigs);
        };
        return !newSource.multiviewSources.every(isSourceConfigsIdentical) as boolean;
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }
}
