import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    HostListener,
    AfterViewInit,
    ElementRef,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom, interval } from "rxjs";
import { take } from "rxjs/operators";

// Services
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { ResizeService } from "../../../services/resize.service";

// Models
import { Constants } from "../../../constants/constants";
import { MediaConnectSource, UserPermissions } from "../../../models/shared";
import { Tag } from "../../../models/shared";
import { MediaConnectSourcesService } from "../mediaconnect-sources.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { GraphsService } from "src/app/services/graphs.service";
import { urlBuilder } from "@zixi/shared-utils";
import { MediaConnectSourceLayouts } from "./mc-source.layout";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "src/app/services/title.service";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { UrlBuilderService } from "src/app/services/url-builder.service";
import { SourcesService } from "../sources.service";
import { TagsService } from "../../configuration/tags/tags.service";

@Component({
    selector: "app-mc-source",
    templateUrl: "./mc-source.component.html"
})
export class MediaConnectSourceComponent extends MediaConnectSourceLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    urlBuilder = urlBuilder;
    constants = Constants;
    source: MediaConnectSource;
    sourceName: string;
    sourceId: number;
    sourceCluster: string;
    resourceTags: Tag[];
    refreshing = false;

    userPermissions: UserPermissions;
    loadingDetails = true;
    isAdmin: boolean;

    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    widgetsToRemoveOrAdd: { title: string; toHide: boolean }[] = [];

    private sourcesSubscription: Subscription;
    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private resizeSubscription: Subscription;
    private refreshThumbnailSubscription: Subscription;

    primaryDetails = this.sourcePrimaryDetails;
    secondaryDetails = this.sourceSecondaryDetails;
    widgets = this.sourceWidgets;
    widgetHeaders = this.sourceWidgetHeaders;

    protected updateDetailContent(title: string) {
        switch (title) {
            // Primary
            case this.translatedNames.SOURCE_THUMBNAIL:
                return {
                    content: "-",
                    object: this.source,
                    isThumbnail: true
                };
            case this.translatedNames.TAGS:
                return {
                    content: "-",
                    object: this.source,
                    tags: this.source.resourceTags,
                    type: "mediaconnect_source",
                    canEdit: () => this.canEdit(this.source)
                };
            case this.translatedNames.PRIORITY:
                return {
                    content: this.tagsService.isObjectVip(this.source.resourceTags) ? "Yes" : "No"
                };
            case this.translatedNames.AWS_MEDIACONNECT_FLOW:
                return {
                    content:
                        !this.source.medialive_channel_id &&
                        this.source.mediaconnect_flow_id &&
                        this.source.mediaconnectFlow &&
                        this.source.mediaconnectFlow.region
                            ? this.source.mediaconnectFlow.name + " @ " + this.source.mediaconnectFlow.region
                            : "",
                    link: urlBuilder
                        .getRegularChannelUrl(
                            this.source.mediaconnectFlow?.id,
                            "mediaconnect",
                            this.source.mediaconnectFlow?.name
                        )
                        .join("/")
                };
            case this.translatedNames.AWS_MEDIALIVE_CHANNEL:
                return {
                    content:
                        this.source.medialive_channel_id && this.source.mediaLiveChannel
                            ? this.source.mediaLiveChannel.name + " @ " + this.source.mediaLiveChannel.region
                            : "",
                    link: urlBuilder
                        .getRegularChannelUrl(
                            this.source.mediaLiveChannel?.id,
                            "medialive",
                            this.source.mediaLiveChannel?.name
                        )
                        .join("/")
                };
            // Secondary
            case this.translatedNames.TYPE:
                return {
                    content: this.translate.instant("TO_MEDIACONNECT")
                };
            case this.translatedNames.LATENCY:
                return {
                    content:
                        this.source.protocol !== "cdi" &&
                        this.source.protocol !== "st2110-jpegxs" &&
                        this.source.latency
                            ? this.source.latency
                            : "",
                    unit: "ms"
                };
            case this.translatedNames.MAX_SYNC_BUFFER:
                return {
                    content:
                        (this.source.protocol === "cdi" || this.source.protocol === "st2110-jpegxs") &&
                        this.source.latency
                            ? this.source.latency
                            : "",
                    unit: "ms"
                };
            case this.translatedNames.MAX_BITRATE:
                return {
                    content: this.source.max_bitrate
                        ? this.sharedService.getKBPSContent(this.source.max_bitrate / 1000)
                        : "",
                    unit: "kbps"
                };
            case this.translatedNames.ALERTING_PROFILE:
                return {
                    content: this.source.alertingProfile?.name ?? "",
                    link:
                        "/" + this.constants.urls.configuration.eventsManagement + "/" + this.source.alertingProfile?.id
                };
            case this.translatedNames.AWS_ACCOUNT:
                return {
                    content: this.source.awsAccount ? this.source.awsAccount.name : ""
                };
            case this.translatedNames.REGION:
                return {
                    content: this.source.elemental_link_region ? this.source.elemental_link_region : ""
                };
            case this.translatedNames.INPUT:
                return {
                    content:
                        this.source.feeder_id ||
                        this.source.broadcaster_id ||
                        this.source.input_id ||
                        this.source.elemental_link_id
                            ? "-"
                            : "",
                    object: this.source
                };
            case this.translatedNames.INGEST_PORT:
                return {
                    content: this.source.ingest_port ?? ""
                };
            case this.translatedNames.WHITELIST_CIDR:
                return {
                    content: this.source.whitelist_cidr ?? ""
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    protected addOrRemoveFromDetails() {
        this.primaryDetails = [...this.primaryDetails];
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ss: MediaConnectSourcesService,
        private sourcesService: SourcesService,
        private modalService: ModalService,
        protected sharedService: SharedService,
        protected userService: UsersService,
        private resizeService: ResizeService,
        private mixpanelService: MixpanelService,
        protected gs: GraphsService,
        protected translate: TranslateService,
        private titleService: TitleService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private urlBuildService: UrlBuilderService,
        private tagsService: TagsService
    ) {
        super(translate, gs, userService, sharedService);
        this.route.paramMap.subscribe(params => {
            this.sourceName = params.get("name");
            const sourceId = urlBuilder.decode(params.get("sourceId"));
            this.sourceId = sourceId;

            if (this.sourceName) this.source = this.ss.getCachedMediaConnectSource(this.sourceName);
            if (!this.source) return this.cancel();

            this.refreshThumbnail();

            if (!this.source.hasFullDetails) this.ss.refreshMediaConnectSource(this.source);

            // Set Title
            this.titleService.setTitle("SOURCE", "", this.source);

            // resourceTags
            this.sharedService
                .getResourceTagsByType("source")
                .pipe(take(1))
                .subscribe((tags: Tag[]) => {
                    this.resourceTags = tags;
                    this.updateWidgetsToRemoveOrAdd();
                    this.addOrRemoveFromWidgets();
                });

            this.updateAllThings();
        });
    }

    updateAllThings() {
        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
    }

    updateWidgetsToRemoveOrAdd() {
        if (!this.source) return;
        this.widgetsToRemoveOrAdd = [
            { title: this.translatedNames.TR101, toHide: ["cdi", "st2110-jpegxs"].includes(this.source.protocol) }
        ];
    }

    close() {
        this.router.navigate([Constants.urls.sources]);
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.source.active_mute ? "unmute" : "mute") + " source");
        this.ss.updateMediaConnectSource(this.source, {
            muted: !this.source.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "source" + " until: " + date.toISOString());
        await this.ss.updateMediaConnectSource(this.source, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.ss.refreshMediaConnectSource(this.source, true));
        this.refreshing = false;
    }

    startThumbnailRefresh() {
        this.refreshThumbnailSubscription = interval(8000).subscribe(() => {
            this.refreshThumbnail();
        });
    }

    stopThumbnailRefresh() {
        this.refreshThumbnailSubscription.unsubscribe();
    }

    refreshThumbnail() {
        this.sourcesService.refreshMediaConnectSourceThumbnail(this.source);
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isSourceMultiselect"))
            this.isMultiSelect = localStorage.getItem("isSourceMultiselect") === "true" ? true : false;

        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        //
        this.sourcesSubscription = this.ss.mediaconnectSources.subscribe(sources => {
            const source = sources.find((s: MediaConnectSource) => s.name === this.sourceName);
            if (source) this.source = source;
            if (this.source?.hasFullDetails) {
                this.updateAllThings();
                this.loadingDetails = false;
            }
        });

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isAdmin = !!user.is_admin || !!user.is_objects_manager;
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateWidgetsToRemoveOrAdd();
            this.addOrRemoveFromWidgets();
        });

        // Start Thumbnail Refresh
        this.startThumbnailRefresh();

        // set initial layout
        await this.revertLayoutChanges();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);
    }

    ngOnDestroy() {
        if (this.sourcesSubscription) this.sourcesSubscription.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
        this.stopThumbnailRefresh();
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    cancel() {
        this.gotoSources();
    }

    gotoSources() {
        this.close();
    }

    editUrl() {
        return this.source.elemental_link_id
            ? [
                  Constants.urls.sources,
                  "elemental_link",
                  this.urlBuildService.encodeRFC3986URIComponent(this.source.name),
                  "edit"
              ]
            : [
                  Constants.urls.sources,
                  "mediaconnect",
                  this.urlBuildService.encodeRFC3986URIComponent(this.source.name),
                  "edit"
              ];
    }

    async deleteSource() {
        await this.modalService.confirm(
            "DELETE",
            "SOURCE",
            async () => {
                const id = this.source.id;
                const result = await this.ss.deleteMediaConnectSource(this.source);
                if (result) {
                    this.mixpanelService.sendEvent("delete source", { id });
                    this.gotoSources();
                } else return false;
            },
            this.source.name
        );
    }

    editSource(source: MediaConnectSource): void {
        if (source.elemental_link_id) {
            this.router.navigate([Constants.urls.sources, "elemental_link", source.name, "edit"]);
        } else {
            this.router.navigate([Constants.urls.sources, "mediaconnect", source.name, "edit"]);
        }
    }

    cloneSource(source: MediaConnectSource): void {
        if (source.elemental_link_id) {
            this.router.navigate([Constants.urls.sources, "elemental_link", source.name, "clone"]);
        } else {
            this.router.navigate([Constants.urls.sources, "mediaconnect", source.name, "clone"]);
        }
    }

    canEdit(source: MediaConnectSource) {
        return this.sharedService.canEditZixiObject(source, this.resourceTags, this.userPermissions);
    }

    config() {
        this.ss.mediaConnectSourceConfigHelp(this.source);
    }

    async addChannel() {
        this.router.navigate([Constants.urls.channels, "new", "source", "mediaconnect", this.source.name]);
    }

    public refreshSource = () => {
        this.ss.refreshMediaConnectSource(this.source.id, true);
    };

    refreshMediaConnectSourcePromise() {
        return firstValueFrom(this.ss.refreshMediaConnectSource(this.source.id, true));
    }
}
