import { Component, Input, OnChanges, SimpleChanges, inject } from "@angular/core";
import _ from "lodash";
import { MediaConnectSource, MediaConnectSourceMetadata } from "src/app/models/shared";
import { MediaConnectSourcesService } from "../../mediaconnect-sources.service";
import { TransportStream } from "@aws-sdk/client-mediaconnect";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let $: any;

type StreamInfoNode = {
    id: string;
    text: string;
    parent: string;
    state?: { opened?: boolean };
};

@Component({
    selector: "app-mc-source-stream-info",
    templateUrl: "./mc-source-stream-info.component.html"
})
export class MediaConnectSourceStreamInfoComponent implements OnChanges {
    @Input() identifier: string;
    @Input() source: MediaConnectSource;
    @Input() expandPrograms?: boolean = true;

    streamInfo: StreamInfoNode[] = [];
    loading = true;

    private mcSourcesService = inject(MediaConnectSourcesService);

    async ngOnChanges(changes: SimpleChanges) {
        // Check for source changed
        let sourceChanged = false;
        if (
            changes.source &&
            ((!changes.source.previousValue && changes.source.currentValue) ||
                (!changes.source.previousValue.sourceMetadata && changes.source.currentValue.sourceMetadata) ||
                (changes.source.previousValue &&
                    changes.source.currentValue &&
                    changes.source.previousValue.id !== changes.source.currentValue.id))
        ) {
            sourceChanged = true;
        }

        if (sourceChanged) {
            this.loading = true;
            this.makeLikeATree();
        }
    }

    private async makeLikeATree() {
        const source = this.mcSourcesService.getCachedMediaConnectSource(undefined, this.source.id);
        if (!source) {
            this.loading = false;
            this.streamInfo = [];
            return;
        }

        this.source = source;

        if (!this.source.sourceMetadata) {
            this.loading = false;
            this.streamInfo = [];
            return;
        }

        const streamInfo = this.buildTree(this.source.sourceMetadata);
        if (_.isEqual(streamInfo, this.streamInfo)) {
            this.loading = false;
            return;
        }

        this.streamInfo = streamInfo;

        $(() => {
            $("#stream_info-" + this.identifier).jstree({
                core: {
                    multiple: false,
                    animation: true,
                    check_callback: false,
                    dblclick_toggle: true,
                    themes: {
                        icons: false
                    },
                    data: this.streamInfo
                },
                version: 1
            });

            $("#stream_info-" + this.identifier).jstree(true).settings.core.data = this.streamInfo;
            $("#stream_info-" + this.identifier)
                .jstree(true)
                .refresh();
        });

        this.loading = false;
    }

    private buildTree(sourceMetadata: MediaConnectSourceMetadata): StreamInfoNode[] {
        if (!sourceMetadata) return [];

        let programs: StreamInfoNode[] = [];
        if (sourceMetadata.transportMediaInfo?.Programs) {
            const programsData = sourceMetadata.transportMediaInfo.Programs;

            programs.push({
                id: "programs",
                text: "Programs" + " (" + programsData.length + ")",
                parent: "#",
                state: { opened: true }
            });

            for (const programData of programsData.sort((a, b) => (a.ProgramNumber ?? 0) - (b.ProgramNumber ?? 0))) {
                const programNode: StreamInfoNode = {
                    id: "programs_" + programData.ProgramNumber,
                    text: "Program #" + programData.ProgramNumber,
                    parent: "programs",
                    state: { opened: this.expandPrograms }
                };

                const pmtPidNode =
                    programData.ProgramPid != null
                        ? [
                              {
                                  id: "pmt_programs_" + programData.ProgramNumber,
                                  text: "PMT PID: " + this.print_pid_number(programData.ProgramPid),
                                  parent: programNode.id
                              }
                          ]
                        : [];

                const pcrPidNode =
                    programData.PcrPid != null
                        ? [
                              {
                                  id: "pcr_programs_" + programData.ProgramNumber,
                                  text: "PCR PID: " + this.print_pid_number(programData.PcrPid),
                                  parent: programNode.id
                              }
                          ]
                        : [];

                const elementaryPidsNodeId = "epids_programs_" + programData.ProgramNumber;
                const elementaryPidsNode = {
                    id: elementaryPidsNodeId,
                    text: "Elementary PIDs",
                    parent: programNode.id,
                    state: { opened: this.expandPrograms }
                };

                let elementaryPidsNodes: StreamInfoNode[] = [];
                for (const epid of programData.Streams?.sort((a, b) => (a.Pid ?? 0) - (b.Pid ?? 0)) ?? []) {
                    if (epid.Pid == null) continue;

                    const currentEpidNodeId = elementaryPidsNodeId + "_pid_" + epid.Pid;

                    let currentEpidNode: StreamInfoNode[] = [
                        {
                            id: currentEpidNodeId,
                            text: ["PID:", this.print_pid_number(epid.Pid)].join(" "),
                            parent: elementaryPidsNodeId,
                            state: { opened: this.expandPrograms }
                        }
                    ];

                    currentEpidNode = currentEpidNode.concat(this.create_generic_pid_data(epid, currentEpidNodeId));

                    elementaryPidsNodes = elementaryPidsNodes.concat(currentEpidNode);
                }

                programs = programs.concat(
                    [programNode],
                    pmtPidNode,
                    pcrPidNode,
                    [elementaryPidsNode],
                    elementaryPidsNodes
                );
            }
        }

        const nodes = ([] as StreamInfoNode[]).concat(programs);

        if (this.streamInfo?.length > 0)
            for (const node of nodes) {
                if (!node.state) node.state = {};
                const currentNode = this.getNodeOpen(node.id);
                node.state.opened = currentNode?.state?.opened ?? node.state.opened ?? false;
            }

        return nodes;
    }

    private getNodeOpen(id: string) {
        const node = $("#stream_info-" + this.identifier)
            .jstree(true)
            .get_node(id);
        return node;
    }

    private create_generic_pid_data(data: TransportStream, parent: string): StreamInfoNode[] {
        const result: StreamInfoNode[] = [];
        if (!data) return result;

        if (data.StreamType != null)
            result.push({
                id: parent + "_pid_type",
                text: `Type: ${data.StreamType}`,
                parent: parent
            });

        if (data.Codec != null)
            result.push({
                id: parent + "_pid_codec",
                text: `Codec: ${data.Codec}`,
                parent: parent
            });

        if (data.FrameRate != null)
            result.push({
                id: parent + "_pid_fps",
                text: `FPS: ${data.FrameRate}`,
                parent: parent
            });

        if (data.FrameResolution?.FrameWidth != null)
            result.push({
                id: parent + "_pid_width",
                text: `Width: ${data.FrameResolution.FrameWidth}`,
                parent: parent
            });

        if (data.FrameResolution?.FrameHeight != null)
            result.push({
                id: parent + "_pid_height",
                text: `Height: ${data.FrameResolution?.FrameHeight}`,
                parent: parent
            });

        if (data.Channels != null)
            result.push({
                id: parent + "_pid_channels",
                text: `Channels: ${data.Channels}`,
                parent: parent
            });

        if (data.SampleRate != null)
            result.push({
                id: parent + "_pid_sample_rate",
                text: `Sample rate: ${data.SampleRate}`,
                parent: parent
            });

        if (data.SampleSize != null)
            result.push({
                id: parent + "_pid_sample_size",
                text: `Sample size: ${data.SampleSize}`,
                parent: parent
            });

        return result;
    }

    private print_pid_number(pid: number) {
        return [pid, " (0x", pid.toString(16).toUpperCase(), ")"].join("");
    }
}
