import { Component, Input, inject } from "@angular/core";
import { MediaConnectSource } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-mc-source-stream",
    templateUrl: "./mc-source-stream.component.html"
})
export class MediaConnectSourceStreamComponent {
    @Input() source: MediaConnectSource;
    @Input() canEdit: boolean;
    expandPrograms = true;
    showOverlay = true;
    urls = Constants.urls;
    private deviceService = inject(DeviceDetectorService);
    canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());

    constructor() {
        if (localStorage.getItem("streamInfoExpandPrograms")) {
            if (localStorage.getItem("streamInfoExpandPrograms") === "false") this.expandPrograms = false;
            else this.expandPrograms = true;
        }
    }

    saveExpandPrograms() {
        localStorage.setItem("streamInfoExpandPrograms", this.expandPrograms.toString());
    }
}
