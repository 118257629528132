<content-loading-animation *ngIf="loadingDetails && !source"></content-loading-animation>

<!-- New -->
<div class="details-panel new-page" *ngIf="source && !loadingDetails">
    <!-- Header -->
    <app-details-page-header [tags]="source.resourceTags" [canEdit]="canEdit(source)" [canDisable]="canEdit(source)" (closePage)="close()">
        <zx-source title [model]="source" [showStatusIcon]="true" [showStatusText]="false" [showLink]="false" [showTag]="false"></zx-source>
        <app-details-page-header-action-buttons
            action-buttons
            [canEdit]="canEdit(source)"
            [isEnabled]="!!source.is_enabled"
            [muted]="source.active_mute"
            (toggleMute)="toggleMute()"
            [editUrl]="urlBuilder.getSourceActionUrl(sourcesService.getRoutingSourceType(source), source.id, source.name, 'edit')"
            [canToggle]="canEdit(source)"
            (toggle)="toggle()"
            (refresh)="refresh()"
            [refreshing]="refreshing"
        ></app-details-page-header-action-buttons>
        <app-details-page-header-drop-down
            drop-down
            [canEdit]="canEdit(source)"
            [cloneBtn]="source.type !== 'monitor_only'"
            [shareBtn]="true"
            [add]="{
                route: [constants.urls.channels, 'new', 'source', source.inputCluster.dns_prefix, source.name],
                text: ('ADD_CHANNEL' | translate)
            }"
            [userPermissions]="
                !resourceTags?.length || (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
            "
            (clone)="cloneSource(source)"
            (delete)="deleteSource()"
            (share)="shareSource(source)"
            (muteUntil)="muteUntil($event)"
            [configBtn]="
                !source.monitor_only &&
                source.source_id == null &&
                source.mediaconnect_flow_arn == null &&
                (source.transcode_profile_id == null || source.type === 'multiview') &&
                source.type !== 'file' &&
                source.type !== 'multiplex' &&
                source.type !== 'multiview'
            "
            (config)="config()"
            [tracerouteBtn]="source._frontData.isConnected && source.status?.trace"
            (traceroute)="traceroute()"
            [isEnabled]="!!source.is_enabled"
            [editUrl]="urlBuilder.getSourceActionUrl(sourcesService.getRoutingSourceType(source), source.id, source.name, 'edit')"
            [muted]="source.active_mute"
            (toggleMute)="toggleMute()"
            [canToggle]="canEdit(source)"
            (toggle)="toggle()"
            [altActionBtn]="recoveryState"
            (altAction)="toggleDR()"
        ></app-details-page-header-drop-down>
    </app-details-page-header>
    <!-- Content -->
    <spinner-animation *ngIf="!isWidgetFullyLoaded"></spinner-animation>
    <div class="details-content" #scrollArea>
        <div class="scroll-content">
            <ng-container *ngIf="isWidgetFullyLoaded">
                <app-layout-buttons
                    *ngIf="!isMobile"
                    [savingLayout]="savingLayout"
                    (saveLayout)="saveLayout()"
                    (resetLayout)="resetLayout()"
                    (revertLayout)="revertLayoutChanges()"
                    [layoutChanged]="layoutChanged"
                    [isLocked]="isLocked"
                    (lockChanged)="lockChanged($event)"
                ></app-layout-buttons>
                <!-- Details-section -->
                <app-details-section
                    #primaryDetailsArea
                    [(detailsList)]="primaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isPrimaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isPrimaryDetails]="true"
                    [isLocked]="isLocked"
                    (refreshPage)="refresh()"
                    class="primary"
                    [ngClass]="{ sticky: isPrimaryDetailsSectionPinned }"
                ></app-details-section>
                <app-details-section
                    #secondaryDetailsArea
                    [(detailsList)]="secondaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isSecondaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isLocked]="isLocked"
                    [(isSectionHidden)]="isSecondaryDetailsSectionHidden"
                    (isSectionHiddenChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [ngClass]="{
                        sticky: isSecondaryDetailsSectionPinned || (isSecondaryDetailsSectionHidden && (isPrimaryDetailsSectionPinned || isWidgetHeaderPinned)),
                        hidden: isSecondaryDetailsSectionHidden
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && (isSecondaryDetailsSectionPinned || isSecondaryDetailsSectionHidden)
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionHidden
                                ? 40
                                : -1,
                        'z-index': constants.zindexValues.second
                    }"
                >
                </app-details-section>
                <!-- Alerts -->
                <div>
                    <zx-active-states
                        [activeStates]="source.activeStates"
                        [refreshFunction]="refreshSource"
                        [canEdit]="canEdit(source)"
                        [objectName]="source.name"
                    ></zx-active-states>
                    <ngb-alert [dismissible]="false" type="info" *ngIf="source?.active_flapping && source?.is_enabled">
                        <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
                        <zx-date-time-zone [dateTime]="source.flapping" />
                    </ngb-alert>
                    <ngb-alert [dismissible]="false" type="info" *ngIf="maintenanceWarning">
                        <strong>{{ "INFO" | translate }}</strong> - {{ "ALL_TARGET_BX_MAINTENANCE" | translate }}
                    </ngb-alert>
                </div>
                <!-- Tabs -->
                <app-widget-section-header
                    [(widgetHeaders)]="widgetHeaders"
                    (widgetSelectedChange)="onWidgetSelectChange($event)"
                    [(isSectionPinned)]="isWidgetHeaderPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isMultiSelect)]="isMultiSelect"
                    (isMultiSelectChange)="multiSelectChanged($event)"
                    [multiWidgetHeaders]="multiWidgetHeaders"
                    [isLocked]="isLocked"
                    [isMobile]="isMobile"
                    [ngClass]="{
                        sticky: isWidgetHeaderPinned
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight + secondaryDetailsAreaHeight - 1
                                : isPrimaryDetailsSectionPinned && !isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? secondaryDetailsAreaHeight - 1
                                : -1,
                        'z-index': constants.zindexValues.first
                    }"
                >
                </app-widget-section-header>
                <!-- Content -->
                <app-widget-section
                    [(widgets)]="widgets"
                    (widgetsChange)="onWidgetsLayoutChange($event)"
                    [isMultiSelect]="isMultiSelect"
                    [isLocked]="isLocked"
                ></app-widget-section>
            </ng-container>
        </div>
    </div>
</div>
