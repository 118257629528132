import { Component, OnChanges, Input, inject, SimpleChanges, ComponentRef } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import {
    Broadcaster,
    KeyMap,
    MediaConnectFlow,
    MediaConnectSource,
    SomeZixiObject,
    ZixiPlus
} from "../../../../models/shared";

import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { Source } from "../../../../models/shared";
import {
    AdaptiveChannel,
    AnyTarget,
    ChannelTypes,
    DeliveryChannel,
    FailoverChannel,
    MediaLiveChannel,
    TargetObjectType,
    ZixiPullTarget
} from "src/app/pages/channels/channel";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { SourcesService } from "../../sources.service";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { SharedService } from "src/app/services/shared.service";
import { ZecsService } from "src/app/pages/zecs/zecs.service";
import { Feeder, Receiver, Zec } from "src/app/pages/zecs/zecs/zec";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { urlBuilder } from "@zixi/shared-utils";
import { ZxLinkTextComponent } from "src/app/components/shared/zx-link-text/zx-link-text.component";
import { Constants } from "src/app/constants/constants";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { MediaConnectSourcesService } from "../../mediaconnect-sources.service";

export interface DownstreamObjectTableData {
    url: string;
    type: string;
    id: number;
    superType: string;
    object: DownstreamObjectTypes;
}

export type DownstreamObjectTypes =
    | ChannelTypes
    | Broadcaster
    | Source
    | MediaConnectSource
    | Feeder
    | Zec
    | Receiver
    | TargetObjectType;

@Component({
    selector: "app-source-downstream-objects",
    templateUrl: "./source-downstream-objects.component.html"
})
export class SourceDownstreamObjectsComponent implements OnChanges {
    @Input() source: Source | MediaConnectSource;
    currentSortDirection: string;
    loading = true;
    objects: DownstreamObjectTableData[] = [];
    private objectsBS$ = new BehaviorSubject<DownstreamObjectTableData[]>([]);
    selectedRows: Array<DownstreamObjectTableData> = [];

    private translateService = inject(TranslateService);
    private sourcesService = inject(SourcesService);
    private mediaconnectSourcesService = inject(MediaConnectSourcesService);
    private channelsService = inject(ChannelsService);
    private zecsService = inject(ZecsService);
    private targetsService = inject(TargetsService);
    private sharedService = inject(SharedService);
    private statusTextPipe = inject(StatusTextPipe);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);

    tableColumnsSchema: TableSchema<KeyMap<DownstreamObjectTableData>>[] = [
        {
            header: this.translateService.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 160,
            sticky: 1,
            component: ZxLinkTextComponent,
            assignComponentsInputs: (
                ComponentRef: ComponentRef<ZxLinkTextComponent>,
                row: KeyMap<DownstreamObjectTableData>
            ) => {
                const CompRef = ComponentRef.instance;
                CompRef.link = row.url;
                CompRef.text = row.object.name;
                CompRef.target = "_self";
                CompRef.hideIcon = true;
            },
            sortBy: row => row.object.name,
            textValue: row => row.object.name
        },
        {
            header: this.translateService.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({
                modelCallBack: row => row.object as unknown as Partial<SomeZixiObject>,
                showOtherIcons: true
            }),
            textValue: row => this.translateService.instant(this.statusTextPipe.transform(row.object)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? (row.object as ZixiPlus)._sortData.sortableStatusAsc
                    : (row.object as ZixiPlus)._sortData.sortableStatusDesc,
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translateService.instant(this.statusTextPipe.simpleTransform(row.object)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translateService.instant("TYPE"),
            columnDef: "type",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<DownstreamObjectTableData>>(
                row => this.translateService.instant(row.superType.toUpperCase()),
                row => this.translateService.instant(row.superType.toUpperCase()),
                () => true
            ),
            sortBy: row => this.translateService.instant(row.superType.toUpperCase()),
            textValue: row => this.translateService.instant(row.superType.toUpperCase()),
            valueToExport: row => this.translateService.instant(row.superType.toUpperCase()),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translateService.instant(row.superType.toUpperCase()),
            columnSelectOptions: ["Channel", "Source", "MediaConnect Source", "Target"]
        }
    ];

    async ngOnChanges(changes: SimpleChanges) {
        const { currentValue, previousValue } = changes.source;
        if (currentValue?.id && currentValue?.id !== previousValue?.id) {
            this.loading = true;
            this.selectedRows = [];
            this.objects = [];
            this.objectsBS$.next(this.objects);
            this.prepTableData();
        }
    }

    async refresh() {
        this.prepTableData();
    }

    async multiAction(action: string, func: (object) => Promise<unknown>, options?) {
        const result = await this.modalService.confirmMultiple(
            action,
            "OBJECT",
            this.selectedRows.map(o => {
                const model: any = o.object;
                model.superType = o.superType;
                return model;
            }),
            func,
            options
        );
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(
                this.translateService.instant(action).toLowerCase() + " multiple downstream source objects"
            );
            if (action === "DELETE") this.selectedRows = [];
        }
        this.refresh();
    }

    async multiEdit() {
        await this.modalService.editMultiple(
            "OBJECT",
            this.selectedRows.map(o => {
                const model: any = o.object;
                model.superType = o.superType;
                return model;
            }),
            async (object, model) => {
                if (object.superType === "source") {
                    return this.sourcesService.updateSource(object, model);
                } else if (object.superType === "mediaconnect_source") {
                    return this.mediaconnectSourcesService.updateMediaConnectSource(object, model);
                } else if (object.superType === "target") {
                    return this.targetsService.updateTarget(object, model);
                } else if (object.superType === "channel") {
                    return this.channelsService.updateChannel(object, model);
                } else if (
                    object.superType === "zec" ||
                    object.superType === "feeder" ||
                    object.superType === "receiver"
                ) {
                    return this.zecsService.updateZec(object, model, object.superType.toUpperCase());
                }
                // clusters
            },
            { customContainer: ".panel-container" }
        );
        this.refresh();
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async object => {
            if (object.superType === "source") {
                return this.sourcesService.updateSource(object, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            } else if (object.superType === "mediaconnect_source") {
                return this.mediaconnectSourcesService.updateMediaConnectSource(object, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            } else if (object.superType === "target") {
                return this.targetsService.updateTarget(object, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            } else if (object.superType === "channel") {
                return this.channelsService.updateChannel(object, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            } else if (object.superType === "zec" || object.superType === "feeder" || object.superType === "receiver") {
                return this.zecsService.updateZec(
                    object,
                    {
                        muted: mute,
                        muted_until: null,
                        flapping: null
                    },
                    object.superType.toUpperCase()
                );
            }
            // cluster
        });
    }

    multiDelete() {
        this.multiAction("DELETE", async object => {
            if (object.superType === "source") {
                return this.sourcesService.deleteSource(object, false);
            } else if (object.superType === "mediaconnect_source") {
                return this.mediaconnectSourcesService.deleteMediaConnectSource(object);
            } else if (object.superType === "target") {
                return this.targetsService.deleteTarget(object);
            } else if (object.superType === "channel") {
                return this.channelsService.deleteChannel(object);
            } else if (object.superType === "zec" || object.superType === "feeder" || object.superType === "receiver") {
                return this.zecsService.deleteZec(object, object.superType.toUpperCase());
            }
            // cluster?
        });
    }

    multiReset() {
        this.multiAction(
            "RESET",
            async object => {
                if (object.superType === "source") {
                    return this.sourcesService.resetTR101(object);
                } else return "ignored";
            },
            { note: "You can only reset Content Analysis and TR101 logs for Source objects" }
        );
    }

    get objects$() {
        return this.objectsBS$.asObservable();
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    async prepTableData() {
        this.objects = [];
        await this.addSource(this.source);

        // remove dupes
        const result: DownstreamObjectTableData[] = this.objects.reduce((unique, o) => {
            if (!unique.some(obj => obj.id === o.id && obj.type === o.type)) {
                unique.push(o);
            }
            return unique;
        }, []);

        // remove source from downstream objects
        const filterResult = result.filter(item => !(item.id === this.source.id && item.type === this.source.type));
        this.objects = filterResult;

        if (this.objects) this.objectsBS$.next(this.objects);
        this.loading = false;
    }

    // logic to get downstream objects, modified from react-flow-diagram
    // probably a lot more could be removed/is unnused, but didn't know if we would want to show downstream objects for other types of objects besides sources
    private async addSource(source: Source | MediaConnectSource) {
        const mediaconnect = source instanceof MediaConnectSource;
        let sourceChannels: false | ChannelTypes[] = [];
        if (!mediaconnect) {
            sourceChannels = (await this.sourcesService.getSourceChannels(source.id)) || [];
        } else {
            if (source.mediaconnect_flow_id && source.mediaconnectFlow) sourceChannels = [source.mediaconnectFlow];
            if (source.medialive_channel_id && source.mediaLiveChannel) sourceChannels = [source.mediaLiveChannel];
        }

        // Channels
        if (sourceChannels && sourceChannels.length) {
            for (const sourceChannel of sourceChannels) {
                // todo: can we just use prep here
                // const preppedChannel = this.channelsService.prepChannel(sourceChannel);
                const channel = await firstValueFrom(this.channelsService.refreshChannel(sourceChannel, false));
                // todo: if we want to show downstream channel targets
                // if (channel) await this.addChannel(channel, false, mediaconnect ? undefined : source);
                if (channel) await this.addChannel(channel, true, mediaconnect ? undefined : source);
            }
        } else {
            if (mediaconnect) await this.addMediaConnectSourceFromObject(source);
            else await this.addSourceFromObject(source, false);
        }

        let sourceSources: false | Source[] = [];
        if (!mediaconnect) {
            sourceSources = (await this.sourcesService.getSourceSources(source.id)) || [];
        }

        if (sourceSources && sourceSources.length) {
            for (const sourceSource of sourceSources) {
                this.addSourceObject(sourceSource);
            }
        }
    }

    private addSourceObject(x: Source, failoverChannel?: FailoverChannel) {
        const data = {
            id: x.id,
            type: x.type,
            superType: "source",
            url: urlBuilder.getRegularSourceUrl(x.id, x.name).join("/"),
            object: x
        };
        this.objects.push(data);
    }

    private addMediaConnectSourceObject(x: MediaConnectSource) {
        const data = {
            id: x.id,
            type: x.type,
            superType: "mediaconnect_source",
            url: Constants.urls.sources + "/mediaconnect/" + x.name,
            object: x
        };
        this.objects.push(data);
    }

    private addChannelObject(x: ChannelTypes) {
        const data = {
            id: x.id,
            type: x.type,
            superType: "channel",
            url: urlBuilder
                .getRegularChannelUrl(x.id, x.type === "delivery" ? "pass-through" : x.type, x.name)
                .join("/"),
            object: x
        };
        this.objects.push(data);
    }

    private addTargetObject(anyT: AnyTarget) {
        const x = anyT.target;
        const data = {
            id: x.id,
            type: x.type,
            superType: "target",
            url: urlBuilder.getRegularTargetUrl(x.id, x.type, x.name).join("/"),
            object: x
        };
        this.objects.push(data);
    }

    private async addSourceFromObject(source: Source, standaloneSource?: boolean, failoverChannel?: FailoverChannel) {
        // Refresh source data
        if (
            !source.readOnly &&
            (standaloneSource ||
                !source.status ||
                this.sharedService.isEmptyObject(source.status) ||
                !source._frontData)
        ) {
            await firstValueFrom(this.sourcesService.refreshSource(source.id, false));
            source = Object.assign({}, this.sourcesService.getCachedSource(undefined, undefined, source.id));
        }

        // Source Object & Status
        source.zixi
            ? this.addSourceObject(source, failoverChannel)
            : this.addMediaConnectSourceObject(source as unknown as MediaConnectSource); // TODO: fix this garbage cast

        if (source.hitless_failover_source_ids?.length > 0 && source.failoverSources.length > 0) {
            for (const failover of source.failoverSources) {
                let failoverSource = failover.source;

                await firstValueFrom(this.sourcesService.refreshSource(failoverSource.id, false));
                failoverSource = Object.assign(
                    {},
                    this.sourcesService.getCachedSource(undefined, undefined, failoverSource.id)
                );

                if (failoverSource?.id) {
                    const tags = failover.is_active ? ["Active"] : [];
                    tags.push(failover.priority === 2 ? "Primary" : failover.priority === 1 ? "Secondary" : "Slate");
                    await this.addSourceFromObject(failoverSource, true);
                }
            }
        }

        if (source.multiplexSources) {
            for (const multiplexComponent of source.multiplexSources) {
                let multiplexSource = multiplexComponent.source;

                await firstValueFrom(this.sourcesService.refreshSource(multiplexSource.id, false));
                multiplexSource = Object.assign(
                    {},
                    this.sourcesService.getCachedSource(undefined, undefined, multiplexSource.id)
                );

                if (multiplexSource?.id) {
                    await this.addSourceFromObject(multiplexSource, true);
                }
            }
        }

        // Intercluster/Chained/Transcoded Sources
        if (source.transcode_source_id ?? source.source_id) {
            let sourceSource = source.transcodeSource ?? source.Source;

            await firstValueFrom(
                this.sourcesService.refreshSource(source.transcode_source_id ?? source.source_id, false)
            );
            sourceSource = Object.assign(
                {},
                this.sourcesService.getCachedSource(
                    undefined,
                    undefined,
                    source.transcode_source_id ?? source.source_id
                )
            );

            await this.addSourceFromObject(sourceSource, true);
        }

        if (source.type === "multiview" && source.multiviewSources?.length > 0) {
            await Promise.all(
                source.multiviewSources.map(async mv => {
                    await firstValueFrom(this.sourcesService.refreshSource(mv.source_id, false));
                    const mvSource = Object.assign(
                        {},
                        this.sourcesService.getCachedSource(undefined, undefined, mv.source_id)
                    );
                    await this.addSourceFromObject(mvSource, true);
                })
            );
        }
    }

    private async addMediaConnectSourceFromObject(source: MediaConnectSource) {
        // Source Object & Status
        this.addMediaConnectSourceObject(source);
    }

    private async addAWSMediaChannelFromObject(
        channel: MediaConnectFlow | MediaLiveChannel
    ): Promise<Broadcaster | undefined> {
        if (!channel || !channel.id) return;
        this.addChannelObject(channel);
    }

    private async addChannel(
        channel: ChannelTypes,
        noTargets: boolean,
        channelSource?: Source
    ): Promise<Broadcaster | undefined> {
        const awsMediaChannel = channel instanceof MediaConnectFlow || channel instanceof MediaLiveChannel;
        let channelBroadcaster: Broadcaster | undefined = undefined;

        if (awsMediaChannel) await this.addAWSMediaChannelFromObject(channel);
        else channelBroadcaster = await this.addChannelFromObject(channel);

        // Setup Sources
        if (channel instanceof MediaConnectFlow) {
            if (channel.source && channel.source.id != null) {
                await this.addMediaConnectSourceFromObject(channel.source);
            }
        } else if (channel instanceof MediaLiveChannel) {
            if (channel.flow && channel.flow.id != null) {
                await firstValueFrom(this.channelsService.refreshMediaConnectFlow(channel.flow.id, false));
                const sourceFlow = this.channelsService.getCachedMediaConnectFlow(channel.flow.id);
                await this.addChannel(sourceFlow, true);
                if (sourceFlow.source) {
                    await this.addMediaConnectSourceFromObject(sourceFlow.source);
                }
            }
        } else {
            const sources: { source: Source; type?: string }[] = channelSource ? [{ source: channelSource }] : [];
            if (!channelSource) {
                if (channel instanceof DeliveryChannel) {
                    for (const src of channel.sources ?? []) {
                        if (src.source?.id !== null) sources.push({ source: src.source });
                    }
                }
                if (channel instanceof FailoverChannel) {
                    if (channel.failoverSource && channel.failover_source_id) {
                        sources.push({ source: channel.failoverSource });
                    }
                }
                if (channel instanceof AdaptiveChannel) {
                    if (channel.slateSource) sources.push({ source: channel.slateSource, type: "Slate" });
                    for (const bitrate of channel.bitrates ?? []) {
                        sources.push({
                            source: bitrate.source,
                            type: !bitrate.profile_id
                                ? `${bitrate.kbps} kbps`
                                : channel.slateSource
                                ? "Primary"
                                : undefined
                        });
                    }
                }
            }

            for (const s of sources) {
                await this.addSourceFromObject(
                    s.source,
                    false,
                    channel instanceof FailoverChannel ? channel : undefined
                );
            }
        }

        if (noTargets) return channelBroadcaster;
        const targetsChannel = channel instanceof FailoverChannel ? channel.deliveryChannel : channel;

        // Publishing Target
        if (targetsChannel instanceof AdaptiveChannel)
            for (const t of targetsChannel.publishingTarget ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "http" }, t));
            }

        // Zixi Push
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.zixiPush ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "push" }, t));
            }

        // rtmp Push
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.rtmpPush ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "rtmp" }, t));
            }

        // udpRtp
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.udpRtp ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "udp_rtp" }, t));
            }

        // RIST
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.rist ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "rist" }, t));
            }

        // SRT
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.srt ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "srt" }, t));
            }

        // NDI
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.ndi ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "ndi" }, t));
            }

        // Zixi Pull
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const target of targetsChannel.zixiPull ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "pull" }, target));
            }

        if (targetsChannel instanceof MediaLiveChannel)
            for (const t of targetsChannel.mediaLiveHttp ?? []) {
                await this.addTargetFromObject(Object.assign({ apiType: "medialive_http" }, t));
            }

        return channelBroadcaster;
    }

    private async addChannelFromObject(channel: ChannelTypes): Promise<Broadcaster | undefined> {
        if (!channel || !channel.id) return;

        // todo
        const hasChannelNode =
            channel instanceof AdaptiveChannel ||
            channel instanceof DeliveryChannel ||
            channel instanceof FailoverChannel;
        if (hasChannelNode) this.addChannelObject(channel);
    }

    private async addTargetFromObject(target: TargetObjectType) {
        if (target.readOnly) return;

        const targetApiType = this.targetsService.getTargetApiType(target);

        let anyT = this.targetsService.getCachedTarget(target.id, targetApiType);
        if (!anyT || !anyT.target.status) {
            await firstValueFrom(this.targetsService.refreshTarget(targetApiType, target.id, false));
            anyT = this.targetsService.getCachedTarget(target.id, targetApiType);
        }
        if (anyT) {
            target = anyT.target;
            this.addTargetObject(anyT);
        }
    }
}
