<spinner-animation *ngIf="loading"></spinner-animation>
<app-table-list
    *ngIf="!loading"
    [tableName]="'source-downstream-objects'"
    [displayTableName]="'DOWNSTREAM_OBJECTS' | translate"
    [data]="objects$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showReport]="false"
    [showPagination]="true"
    [showFilter]="true"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    (currentSortDirection)="onSort($event)"
    [autoRows]="false"
    [(selectedRows)]="selectedRows"
    ><div *ngIf="selectedRows.length > 0">
        <app-zx-action-buttons
            (multiToggleMute)="multiToggleMute($event)"
            (multiEdit)="multiEdit()"
            (multiDelete)="multiDelete()"
            (multiReset)="multiReset()"
            [resetButton]="true"
            [toggleStateButton]="false"
            [enableButton]="false"
        >
        </app-zx-action-buttons>
    </div>
</app-table-list>
