<content-loading-animation *ngIf="loading"></content-loading-animation>
<form
    id="form"
    novalidate
    #form="ngForm"
    (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && !ingestControl.invalid && onSubmit()"
    *ngIf="!loading"
>
    <app-form-title [titles]="['ZIXI', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>
                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>
                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'INPUT' | translate }}">
                            {{ "INPUT" | translate }}
                        </h3>
                    </div>
                    <!-- Input mode-->
                    <fieldset class="form-group">
                        <legend for="input-type-mode">
                            {{ "MODE" | translate }}
                        </legend>
                        <mat-button-toggle-group name="input-type-mode" aria-label="input type mode" [(ngModel)]="inputMode" (change)="inputModeChange()">
                            <mat-button-toggle value="push" [disabled]="isEdit">{{ "PUSH" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="pull" [disabled]="isEdit">{{ "PULL" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <!-- Type -->
                    <fieldset class="form-group bordered">
                        <legend>{{ "APPLICATION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></legend>
                        <div *ngIf="inputMode === 'push'" class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="type_zec"
                                name="type_zec"
                                class="form-check-input"
                                value="zec"
                                [(ngModel)]="application"
                                (change)="resetForm()"
                            />
                            <label class="form-check-label" for="type_zec"
                                >{{ "ZEC" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="ZecContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #ZecContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_ZEC" | translate }}
                                    </p></ng-template
                                ></label
                            >
                        </div>
                        <div *ngIf="inputMode === 'push'" class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="type_feeder"
                                name="type_feeder"
                                class="form-check-input"
                                value="feeder"
                                [(ngModel)]="application"
                                (change)="resetForm()"
                            />
                            <label class="form-check-label" for="type_feeder"
                                >{{ "FEEDER" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="FeederContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #FeederContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_FEEDER" | translate }}
                                    </p></ng-template
                                ></label
                            >
                        </div>
                        <div *ngIf="inputMode === 'push'" class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="type_broadcaster"
                                name="type_broadcaster"
                                class="form-check-input"
                                value="broadcaster"
                                [(ngModel)]="application"
                                (change)="resetForm()"
                            />
                            <label class="form-check-label" for="type_broadcaster"
                                >{{ "BROADCASTER" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="BroadcasterContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #BroadcasterContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_BROADCASTER" | translate }}
                                    </p></ng-template
                                ></label
                            >
                        </div>
                        <div *ngIf="inputMode === 'pull'" class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="type_broadcaster_pull"
                                name="type_broadcaster_pull"
                                class="form-check-input"
                                value="zixi_pull"
                                [(ngModel)]="application"
                                (change)="resetForm()"
                            />
                            <label class="form-check-label" for="type_broadcaster_pull"
                                >{{ "BROADCASTER" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="BroadcasterPullContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #BroadcasterPullContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_BROADCASTER_PULL" | translate }}
                                    </p></ng-template
                                ></label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="type_mediaconnect"
                                name="type_mediaconnect"
                                class="form-check-input"
                                value="mediaconnect"
                                [(ngModel)]="application"
                                (change)="resetForm()"
                            />
                            <label class="form-check-label" for="type_mediaconnect"
                                >{{ "AWS_MEDIACONNECT" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="MediaConnectContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #MediaConnectContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_MEDIACONNECT" | translate }}
                                    </p></ng-template
                                ></label
                            >
                        </div>
                        <div *ngIf="inputMode === 'push'" class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="type_other"
                                name="type_other"
                                class="form-check-input"
                                value="other"
                                [(ngModel)]="application"
                                (change)="resetForm()"
                            />
                            <label class="form-check-label" for="type_other"
                                >{{ "OTHER" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="OtherContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #OtherContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_OTHER" | translate }}
                                    </p></ng-template
                                ></label
                            >
                        </div>
                    </fieldset>
                    <!-- Feeder / Broadcaster/ ZEC / MediaConnect / Other -->
                    <!-- Feeder Select -->
                    <div class="form-group" *ngIf="application === 'feeder'">
                        <label for="feeder_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.feeder_id?.errors }"
                            >{{ "FEEDER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-zec-select
                            name="feeder_id"
                            [(model)]="source.feeder_id"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.feeder_id?.errors }"
                            (modelChange)="getFeederDetails(source.feeder_id, true)"
                            [zecType]="'FEEDER'"
                        ></zx-zec-select>
                        <div *ngIf="form.controls.feeder_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.feeder_id?.errors.required">{{ "FEEDER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Multi Input Mode -->
                    <div class="form-group" *ngIf="!isEdit && !isClone && application === 'feeder'">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="multiMode" name="multiMode" [(ngModel)]="multiMode" />
                            <label class="form-check-label" for="multiMode">{{ "MULTI_INPUT_MODE" | translate }} </label>
                        </div>
                    </div>
                    <!-- Feeder Input Select -->
                    <div class="form-group" *ngIf="application === 'feeder' && singleMode">
                        <label for="feeder_input" [ngClass]="{ 'is-invalid': form.submitted && feederInput.errors }">
                            {{ "FEEDER_INPUT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="feeder_input"
                            name="feeder_input"
                            [items]="feederInputs"
                            [clearable]="false"
                            bindValue="name"
                            placeholder="{{ 'SELECT_FEEDER_INPUT' | translate }}"
                            [(ngModel)]="feederInputId"
                            #feederInput="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && feederInput.errors }"
                            required
                            [loading]="feederDetailsLoading"
                            [searchFn]="feederInputSearch"
                        >
                            <ng-template ng-label-tmp let-item="item" *ngIf="feederDetailsLoading"> {{ "LOADING" | translate }}... </ng-template>
                            <ng-template
                                ng-label-tmp
                                let-item="item"
                                let-index="index"
                                let-search="searchTerm"
                                *ngIf="!feederDetailsLoading && !feederInputs.length"
                            >
                                {{ "SELECT_FEEDER_INPUT" | translate }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item" *ngIf="!feederDetailsLoading">
                                <fa-icon
                                    class="me-1"
                                    [icon]="item | feederInputStatus | statusIcon"
                                    class="status-{{ item | feederInputStatus | statusClass }}"
                                ></fa-icon>
                                <span>{{ item | feederInput }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!feederDetailsLoading">
                                <fa-icon
                                    class="me-1"
                                    [icon]="item | feederInputStatus | statusIcon"
                                    class="status-{{ item | feederInputStatus | statusClass }}"
                                ></fa-icon>
                                <span>{{ item | feederInput }}</span>
                            </ng-template>
                        </ng-select>
                        <small *ngIf="!feederInputs || !feederInputs.length">{{ "NO_INPUTS" | translate }}</small>
                        <div *ngIf="feederInput.invalid" class="invalid-feedback">
                            <div *ngIf="feederInput.errors.required">{{ "FEEDER_INPUT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Feeder Inputs Table -->
                    <div class="form-group" *ngIf="application === 'feeder' && !singleMode">
                        <label for="feeder_inputs" [ngClass]="{ 'is-invalid': form.submitted && selectedFeederInputs.length === 0 }"
                            >{{ "FEEDER_INPUTS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><fa-icon icon="info-circle" [ngbTooltip]="FIContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #FIContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.SOURCE_FEEDER_INPUTS" | translate }}
                                </p></ng-template
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <div class="table-responsive">
                            <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedFeederInputs.length === 0 }">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ "INPUT" | translate }}</th>
                                        <th scope="col">{{ "NAME" | translate }}</th>
                                        <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }}</th>
                                        <th scope="col" class="w-50px text-end">
                                            <fa-icon class="" icon="info-circle" [ngbTooltip]="FIIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                            <ng-template #FIIContent>
                                                <p class="mb-0">{{ "TOOLTIP.SOURCE_FEEDER_INPUT_INSTRUCTIONS" | translate }}</p>
                                            </ng-template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="source.feeder_id && !feederDetailsLoading && (feederInputs.length > 0 || selectedFeederInputs.length > 0)">
                                    <tr *ngFor="let input of selectedFeederInputs; index as index">
                                        <td title="{{ input | feederInput }}" class="maxw-100px ellipsis">
                                            <fa-icon
                                                class="me-1"
                                                [icon]="input | feederInputStatus | statusIcon"
                                                class="status-{{ input | feederInputStatus | statusClass }}"
                                            ></fa-icon>
                                            <span class="d-inline">{{ input | feederInput }}</span>
                                        </td>
                                        <td class="maxw-100px ellipsis">
                                            <input
                                                type="text"
                                                id="source_name_{{ index }}"
                                                name="source_name_{{ index }}"
                                                [(ngModel)]="input.source_name"
                                                duplicateName="sourceNames"
                                                class="form-control form-control-sm form-control-xs"
                                                pattern="{{ constants.validators.source_name }}"
                                                placeholder="{{ 'NAME' | translate }}"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['source_name_' + index]?.errors }"
                                                required
                                            />
                                            <div *ngIf="form.controls['source_name_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['source_name_' + index]?.errors.required">
                                                    {{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['source_name_' + index]?.errors.duplicateName">
                                                    {{ "NAME" | translate }} {{ "MUST_BE_UNIQUE_IN_CLUSTER" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['source_name_' + index]?.errors.pattern">
                                                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_SPECIAL_CHARACTERS" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-select
                                                id="max_bitrate_{{ index }}"
                                                name="max_bitrate_{{ index }}"
                                                [items]="maxBitrates"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="input.max_bitrate"
                                                class="form-control form-control-sm form-control-xs"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectFeederInput(input)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="form-inline">
                                                <div class="form-group stay-inline">
                                                    <label for="feederInputsFilter">{{ "FILTER" | translate }}:</label>
                                                    <input
                                                        type="text"
                                                        name="feederInputsFilter"
                                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                        [(ngModel)]="feederInputsFilter"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let input of getFeederInputs(feederInputs, feederInputsFilter)
                                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                        "
                                        [ngClass]="{ 'cursor-pointer row-hover': !(selectedFeederInputs.length >= this.MAX_INPUTS) }"
                                        (click)="!(selectedFeederInputs.length >= this.MAX_INPUTS) && selectFeederInput(input)"
                                    >
                                        <td title="{{ input | feederInput }}" class="maxw-100px ellipsis">
                                            <fa-icon
                                                class="me-1"
                                                [icon]="input | feederInputStatus | statusIcon"
                                                class="status-{{ input | feederInputStatus | statusClass }}"
                                            ></fa-icon>
                                            <span class="d-inline" title="{{ input | feederInput }}">{{ input | feederInput }}</span>
                                            <!--<ngb-highlight title="{{ input | feederInput }}" [result]="input | feederInput" [term]="feederInputsFilter"></ngb-highlight>-->
                                        </td>
                                        <td class="maxw-100px ellipsis">
                                            <ngb-highlight title="{{ input.name }}" [result]="input.name" [term]="feederInputsFilter"></ngb-highlight>
                                        </td>
                                        <td>{{ input.bitrate | bitrate }}</td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                [disabled]="selectedFeederInputs.length >= this.MAX_INPUTS"
                                                (click)="selectFeederInput(input)"
                                            >
                                                <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="source.feeder_id && feederDetailsLoading">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "LOADING" | translate }}...</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="source.feeder_id && !feederDetailsLoading && getFeederInputs(feederInputs, feederInputsFilter).length === 0">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "NO_INPUTS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!source.feeder_id">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "SELECT_FEEDER" | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="form.submitted && selectedFeederInputs.length === 0" class="invalid-feedback">
                            {{ "AT_LEAST_1_FEEDER_INPUT_IS_REQUIRED" | translate }}.
                        </div>
                        <div class="mt-2" *ngIf="feederInputs && getFeederInputs(feederInputs, feederInputsFilter).length > pageSize">
                            <ngb-pagination
                                class="mb-0"
                                [collectionSize]="getFeederInputs(feederInputs, feederInputsFilter).length"
                                [(page)]="page"
                                [pageSize]="pageSize"
                                [maxSize]="2"
                            ></ngb-pagination>
                        </div>
                    </div>
                    <!-- Broadcaster Select -->
                    <div class="form-group" *ngIf="application === 'broadcaster'">
                        <label for="broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_id?.errors }"
                            >{{ "BROADCASTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-broadcaster-select
                            name="broadcaster_id"
                            [(model)]="source.broadcaster_id"
                            required="true"
                            (modelChange)="getBroadcasterDetails(source.broadcaster_id, true)"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_id?.errors }"
                        ></zx-broadcaster-select>
                        <div *ngIf="form.controls.broadcaster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_id?.errors.required">{{ "BROADCASTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Zec Select -->
                    <div class="form-group" *ngIf="application === 'zec'">
                        <label for="zec_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.zec_id?.errors }"
                            >{{ "ZEC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-zec-select
                            name="zec_id"
                            [(model)]="source.zec_id"
                            required="true"
                            (modelChange)="getZecDetails(source.zec_id, true)"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.zec_id?.errors }"
                        ></zx-zec-select>
                        <div *ngIf="form.controls.zec_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.zec_id?.errors.required">{{ "ZEC" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Multi Input Mode -->
                    <div class="form-group" *ngIf="!isEdit && !isClone && application === 'broadcaster'">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="multiMode" name="multiMode" [(ngModel)]="multiMode" />
                            <label class="form-check-label" for="multiMode">{{ "MULTI_INPUT_MODE" | translate }} </label>
                        </div>
                    </div>
                    <!-- Broadcaster/Zec Input Select -->
                    <div class="form-group" *ngIf="(application === 'broadcaster' && singleMode) || application === 'zec'">
                        <label for="broadcaster_input" [ngClass]="{ 'is-invalid': form.submitted && broadcasterInput.errors }">
                            {{ application === "broadcaster" ? ("BROADCASTER_INPUT" | translate) : ("ZEC_INPUT" | translate)
                            }}<fa-icon icon="asterisk" size="xs"></fa-icon><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="broadcaster_input"
                            name="broadcaster_input"
                            [items]="broadcasterInputs"
                            [clearable]="false"
                            bindValue="id"
                            [placeholder]="application === 'broadcaster' ? ('SELECT_BROADCASTER_INPUT' | translate) : ('SELECT_ZEC_INPUT' | translate)"
                            [(ngModel)]="broadcasterInputId"
                            #broadcasterInput="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && broadcasterInput.errors }"
                            required
                            [loading]="broadcasterDetailsLoading"
                            [searchFn]="broadcasterInputSearch"
                        >
                            <ng-template
                                ng-label-tmp
                                let-item="item"
                                let-index="index"
                                let-search="searchTerm"
                                *ngIf="!broadcasterDetailsLoading && !broadcasterInputs.length"
                            >
                                {{ application === "broadcaster" ? ("SELECT_BROADCASTER_INPUT" | translate) : ("SELECT_ZEC_INPUT" | translate) }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item" *ngIf="!broadcasterDetailsLoading">
                                <fa-icon
                                    class="me-1"
                                    [icon]="item | broadcasterInputStatus | statusIcon"
                                    class="status-{{ item | broadcasterInputStatus | statusClass }}"
                                ></fa-icon>
                                <span>{{ item | broadcasterInput }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!broadcasterDetailsLoading">
                                <fa-icon
                                    class="me-1"
                                    [icon]="item | broadcasterInputStatus | statusIcon"
                                    class="status-{{ item | broadcasterInputStatus | statusClass }}"
                                ></fa-icon>
                                <span>{{ item | broadcasterInput }}</span>
                            </ng-template>
                        </ng-select>
                        <small *ngIf="!broadcasterInputs || !broadcasterInputs.length">{{ "NO_INPUTS" | translate }}</small>
                        <div *ngIf="broadcasterInput.invalid" class="invalid-feedback">
                            <div *ngIf="broadcasterInput.errors.required">{{ "BROADCASTER_INPUT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Broadcaster Input Table -->
                    <div class="form-group" *ngIf="application === 'broadcaster' && !singleMode">
                        <label for="broadcaster_inputs" [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterInputs.length === 0 }"
                            >{{ "BROADCASTER_INPUTS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><fa-icon icon="info-circle" [ngbTooltip]="BIContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #BIContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.SOURCE_BROADCASTER_INPUTS" | translate }}
                                </p></ng-template
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <div class="table-responsive">
                            <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterInputs.length === 0 }">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ "INPUT" | translate }}</th>
                                        <th scope="col" class="w-100px">{{ "BITRATE" | translate }}</th>
                                        <th scope="col">{{ "NAME" | translate }}</th>
                                        <th scope="col" class="w-50px text-end">
                                            <fa-icon class="" icon="info-circle" [ngbTooltip]="BIIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                            <ng-template #BIIContent>
                                                <p class="mb-0">{{ "TOOLTIP.SOURCE_BROADCASTER_INPUT_INSTRUCTIONS" | translate }}</p>
                                            </ng-template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    *ngIf="
                                        source.broadcaster_id &&
                                        !broadcasterDetailsLoading &&
                                        (broadcasterInputs.length > 0 || selectedBroadcasterInputs.length > 0)
                                    "
                                >
                                    <tr *ngFor="let input of selectedBroadcasterInputs; index as index">
                                        <td title="{{ input | broadcasterInput }}" class="maxw-100px ellipsis">
                                            <fa-icon
                                                class="me-1"
                                                [icon]="input | broadcasterInputStatus | statusIcon"
                                                class="status-{{ input | broadcasterInputStatus | statusClass }}"
                                            ></fa-icon>
                                            <span class="d-inline">{{ input | broadcasterInput }}</span>
                                        </td>
                                        <td>{{ input.bitrate | bitrate }}</td>
                                        <td class="maxw-100px ellipsis">
                                            <input
                                                type="text"
                                                id="source_name_{{ index }}"
                                                name="source_name_{{ index }}"
                                                [(ngModel)]="input.source_name"
                                                duplicateName="sourceNames"
                                                class="form-control form-control-sm form-control-xs"
                                                pattern="{{ constants.validators.source_name }}"
                                                placeholder="{{ 'NAME' | translate }}"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['source_name_' + index]?.errors }"
                                                required
                                            />
                                            <div *ngIf="form.controls['source_name_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['source_name_' + index]?.errors.required">
                                                    {{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['source_name_' + index]?.errors.duplicateName">
                                                    {{ "NAME" | translate }} {{ "MUST_BE_UNIQUE_IN_CLUSTER" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['source_name_' + index]?.errors.pattern">
                                                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_SPECIAL_CHARACTERS" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectBroadcasterInput(input)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="form-inline">
                                                <div class="form-group stay-inline">
                                                    <label for="broadcasterInputsFilter">{{ "FILTER" | translate }}:</label>
                                                    <input
                                                        type="text"
                                                        name="broadcasterInputsFilter"
                                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                        [(ngModel)]="broadcasterInputsFilter"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let input of getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter)
                                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                        "
                                        [ngClass]="{ 'cursor-pointer row-hover': !(selectedBroadcasterInputs.length >= this.MAX_INPUTS) }"
                                        (click)="!(selectedBroadcasterInputs.length >= this.MAX_INPUTS) && selectBroadcasterInput(input)"
                                    >
                                        <td title="{{ input | broadcasterInput }}" class="maxw-100px ellipsis">
                                            <fa-icon
                                                class="me-1"
                                                [icon]="input | broadcasterInputStatus | statusIcon"
                                                class="status-{{ input | broadcasterInputStatus | statusClass }}"
                                            ></fa-icon>
                                            <span class="d-inline" title="{{ input | broadcasterInput }}">{{ input | broadcasterInput }}</span>
                                        </td>
                                        <td>{{ input.bitrate | bitrate }}</td>
                                        <td class="maxw-100px ellipsis">
                                            <ngb-highlight title="{{ input.id }}" [result]="input.id" [term]="broadcasterInputsFilter"></ngb-highlight>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                [disabled]="selectedBroadcasterInputs.length >= this.MAX_INPUTS"
                                                (click)="selectBroadcasterInput(input)"
                                            >
                                                <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="source.broadcaster_id && broadcasterDetailsLoading">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "LOADING" | translate }}...</td>
                                    </tr>
                                </tbody>
                                <tbody
                                    *ngIf="
                                        source.broadcaster_id &&
                                        !broadcasterDetailsLoading &&
                                        getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter).length === 0
                                    "
                                >
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "NO_INPUTS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!source.broadcaster_id">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "SELECT_BROADCASTER" | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="form.submitted && selectedBroadcasterInputs.length === 0" class="invalid-feedback">
                            {{ "AT_LEAST_1_BROADCASTER_INPUT_IS_REQUIRED" | translate }}.
                        </div>
                        <div class="mt-2" *ngIf="broadcasterInputs && getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter).length > pageSize">
                            <ngb-pagination
                                class="mb-0"
                                [collectionSize]="getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter).length"
                                [(page)]="page"
                                [pageSize]="pageSize"
                                [maxSize]="2"
                            ></ngb-pagination>
                        </div>
                    </div>
                    <!-- Replace Outputs -->
                    <div
                        class="form-group"
                        *ngIf="
                            (application === 'feeder' && source.feeder_id && !isEdit && !isClone) ||
                            (application === 'broadcaster' && source.broadcaster_id && !isEdit && !isClone)
                        "
                    >
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="cleanup_outputs" name="cleanup_outputs" [(ngModel)]="source.cleanup_outputs" />
                            <label class="form-check-label" for="cleanup_outputs">{{
                                "REPLACE_EXISTING_MANUAL_OUTPUTS_FOR_SELECTED_INPUTS" | translate
                            }}</label>
                        </div>
                        <ngb-alert class="mt-2" [type]="'warning'" [dismissible]="false" *ngIf="source.cleanup_outputs">
                            <strong>{{ "WARNING" | translate }}</strong> - {{ "ZEN_MASTER_WILL_STOP_ALL_CURRENT_OUTPUTS_FOR_THE_SELECTED_INPUTS" | translate }}
                        </ngb-alert>
                    </div>
                    <!-- Broadcaster/ZEC Bonding -->
                    <div class="form-group" *ngIf="application === 'broadcaster' || application === 'zec'">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="bonding" name="bonding" [(ngModel)]="bondingOption" />
                            <label class="form-check-label" for="bonding"
                                >{{ "BONDING" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                        </div>
                    </div>
                    <!-- Feeder Bonding -->
                    <fieldset class="form-group" *ngIf="application === 'feeder'">
                        <legend for="bonding">{{ "BONDING" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></legend>
                        <mat-button-toggle-group name="bonding" aria-label="bonding" [(ngModel)]="bondingOption">
                            <mat-button-toggle [value]="0">{{ "NONE" | translate }}</mat-button-toggle>
                            <mat-button-toggle [value]="1">{{ "MANUAL" | translate }}</mat-button-toggle>
                            <mat-button-toggle [value]="2">{{ "AUTO" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <!-- Feeder Output NIC Select -->
                    <div class="form-group" *ngIf="application === 'feeder' && bondingOption === 0">
                        <label for="feeder_output_nic">
                            {{ "FEEDER_OUTPUT_NIC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="feeder_output_nic"
                            name="feeder_output_nic"
                            [items]="feederOutputsAll"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            placeholder="{{ 'SELECT_FEEDER_OUTPUT_NIC' | translate }}"
                            [(ngModel)]="source.output_nic"
                            [disabled]="!source.feeder_id"
                        >
                        </ng-select>
                    </div>
                    <!-- Broadcaster/ZEC Output NIC Select -->
                    <div class="form-group" *ngIf="(application === 'broadcaster' || application === 'zec') && !bondingOption">
                        <label for="broadcaster_output_nic">
                            {{ application === "broadcaster" ? ("BROADCASTER_OUTPUT_NIC" | translate) : ("ZEC_OUTPUT_NIC" | translate)
                            }}<fa-icon icon="asterisk" size="xs"></fa-icon><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="broadcaster_output_nic"
                            name="broadcaster_output_nic"
                            [items]="broadcasterOutputsAll"
                            [loading]="broadcasterDetailsLoading"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            [placeholder]="
                                application === 'broadcaster' ? ('SELECT_BROADCASTER_OUTPUT_NIC' | translate) : ('SELECT_ZEC_OUTPUT_NIC' | translate)
                            "
                            [(ngModel)]="source.output_nic"
                            [disabled]="(application === 'broadcaster' && !source.broadcaster_id) || (application === 'zec' && !source.zec_id)"
                        >
                        </ng-select>
                    </div>
                    <!-- Feeder Output NIC Table -->
                    <div class="form-group" *ngIf="application === 'feeder' && bondingOption === 1">
                        <label for="feeder_output_nics" [ngClass]="{ 'is-invalid': form.submitted && selectedFeederOutputNICs.length === 0 }"
                            >{{ "FEEDER_OUTPUT_NICS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <div class="table-responsive">
                            <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedFeederOutputNICs.length === 0 }">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ "OUTPUT_NIC" | translate }}</th>
                                        <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }} [kbps]</th>
                                        <th scope="col">{{ "BACKUP" | translate }}</th>
                                        <th scope="col" class="w-50px text-end">
                                            <fa-icon class="" icon="info-circle" [ngbTooltip]="FOIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                            <ng-template #FOIContent>
                                                <p class="mb-0">{{ "TOOLTIP.SOURCE_FEEDER_OUTPUT_INSTRUCTIONS" | translate }}</p>
                                            </ng-template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="source.feeder_id && feederOutputNICs.length > 0">
                                    <tr *ngFor="let output of selectedFeederOutputNICs; index as index">
                                        <td>
                                            {{ output.name }}
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                class="form-control form-control-sm form-control-xs"
                                                id="output_bitrate_{{ index }}"
                                                name="output_bitrate_{{ index }}"
                                                [(ngModel)]="output.max_bitrate"
                                                pattern="^\d*[1-9]\d*$"
                                                placeholder="{{ 'MAX_BITRATE' | translate }}"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_bitrate_' + index]?.errors }"
                                                required
                                            />
                                            <div *ngIf="form.controls['output_bitrate_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.errors.required">
                                                    {{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.errors.pattern">{{ "INVALID_NUMBER" | translate }}.</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="backup_{{ index }}"
                                                    name="backup_{{ index }}"
                                                    [(ngModel)]="output.backup"
                                                />
                                                <label class="form-check-label" for="backup_{{ index }}"></label>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectFeederOutputNIC(output)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="form-inline">
                                                <div class="form-group stay-inline">
                                                    <label for="feederOutputsFilter">{{ "FILTER" | translate }}:</label>
                                                    <input
                                                        type="text"
                                                        name="feederOutputsFilter"
                                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                        [(ngModel)]="feederOutputsFilter"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let output of getOutputNics(feederOutputNICs, feederOutputsFilter)
                                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                        "
                                        class="cursor-pointer row-hover"
                                        (click)="selectFeederOutputNIC(output)"
                                    >
                                        <td class="maxw-120px ellipsis" *ngIf="output.name !== 'Any'">
                                            <ngb-highlight title="{{ output.name }}" [result]="output.name" [term]="feederOutputsFilter"></ngb-highlight>
                                        </td>
                                        <td *ngIf="output.name !== 'Any'"></td>
                                        <td *ngIf="output.name !== 'Any'"></td>
                                        <td class="text-end" *ngIf="output.name !== 'Any'">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                (click)="selectFeederOutputNIC(output)"
                                            >
                                                <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="source.feeder_id && getOutputNics(feederOutputNICs, feederOutputsFilter).length === 0">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "NO_OUTPUTS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!source.feeder_id">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "SELECT_FEEDER" | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="form.submitted && selectedFeederOutputNICs.length === 0" class="invalid-feedback">
                            {{ "AT_LEAST_1_FEEDER_OUTPUT_IS_REQUIRED" | translate }}.
                        </div>
                        <div class="mt-2" *ngIf="feederOutputNICs && getOutputNics(feederOutputNICs, feederOutputsFilter).length > pageSize">
                            <ngb-pagination
                                class="mb-0"
                                [collectionSize]="getOutputNics(feederOutputNICs, feederOutputsFilter).length"
                                [(page)]="page"
                                [pageSize]="pageSize"
                                [maxSize]="2"
                            ></ngb-pagination>
                        </div>
                    </div>
                    <!-- Broadcaster Output NIC Table -->
                    <div class="form-group" *ngIf="(application === 'broadcaster' || application === 'zec') && bondingOption">
                        <label for="broadcaster_output_nics" [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterOutputNICs.length === 0 }"
                            >{{ application === "broadcaster" ? ("BROADCASTER_OUTPUT_NICS" | translate) : ("ZEC_OUTPUT_NICS" | translate)
                            }}<fa-icon icon="asterisk" size="xs"></fa-icon><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <div class="table-responsive">
                            <table
                                class="table table-sm bordered m-0"
                                [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterOutputNICs.length === 0 }"
                            >
                                <thead>
                                    <tr>
                                        <th scope="col">{{ "OUTPUT_NIC" | translate }}</th>
                                        <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }} [kbps]</th>
                                        <th scope="col">{{ "BACKUP" | translate }}</th>
                                        <th scope="col" class="w-50px text-end">
                                            <fa-icon class="" icon="info-circle" [ngbTooltip]="BOIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                            <ng-template #BOIContent>
                                                <p class="mb-0">{{ "TOOLTIP.SOURCE_BROADCASTER_OUTPUT_INSTRUCTIONS" | translate }}</p>
                                            </ng-template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="(source.broadcaster_id || source.zec_id) && broadcasterOutputNICs.length > 0">
                                    <tr *ngFor="let output of selectedBroadcasterOutputNICs; index as index">
                                        <td>
                                            {{ output.name }}
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                class="form-control form-control-sm form-control-xs"
                                                id="output_bitrate_{{ index }}"
                                                name="output_bitrate_{{ index }}"
                                                [(ngModel)]="output.max_bitrate"
                                                pattern="^\d*[1-9]\d*$"
                                                placeholder="{{ 'MAX_BITRATE' | translate }}"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_bitrate_' + index]?.errors }"
                                                required
                                            />
                                            <div *ngIf="form.controls['output_bitrate_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.errors.required">
                                                    {{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.errors.pattern">{{ "INVALID_NUMBER" | translate }}.</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="backup_{{ index }}"
                                                    name="backup_{{ index }}"
                                                    [(ngModel)]="output.backup"
                                                />
                                                <label class="form-check-label" for="backup_{{ index }}"></label>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectBroadcasterOutputNIC(output)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="form-inline">
                                                <div class="form-group stay-inline">
                                                    <label for="broadcasterOutputsFilter">{{ "FILTER" | translate }}:</label>
                                                    <input
                                                        type="text"
                                                        name="broadcasterOutputsFilter"
                                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                        [(ngModel)]="broadcasterOutputsFilter"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let output of getOutputNics(broadcasterOutputNICs, broadcasterOutputsFilter)
                                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                        "
                                        class="cursor-pointer row-hover"
                                        (click)="selectBroadcasterOutputNIC(output)"
                                    >
                                        <td class="maxw-120px ellipsis" *ngIf="output.name !== 'Any'">
                                            <ngb-highlight title="{{ output.name }}" [result]="output.name" [term]="broadcasterOutputsFilter"></ngb-highlight>
                                        </td>
                                        <td *ngIf="output.name !== 'Any'"></td>
                                        <td *ngIf="output.name !== 'Any'"></td>
                                        <td class="text-end" *ngIf="output.name !== 'Any'">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                (click)="selectBroadcasterOutputNIC(output)"
                                            >
                                                <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    *ngIf="
                                        (source.broadcaster_id || source.zec_id) && getOutputNics(broadcasterOutputNICs, broadcasterOutputsFilter).length === 0
                                    "
                                >
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "NO_NICS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!source.broadcaster_id && !source.zec_id">
                                    <tr>
                                        <td colspan="4" class="text-center">
                                            {{ application === "broadcaster" ? ("SELECT_BROADCASTER" | translate) : ("SELECT_ZEC" | translate) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="form.submitted && selectedBroadcasterOutputNICs.length === 0" class="invalid-feedback">
                            {{ "AT_LEAST_1_BROADCASTER_OUTPUT_IS_REQUIRED" | translate }}.
                        </div>
                        <div class="mt-2" *ngIf="broadcasterOutputNICs && getOutputNics(broadcasterOutputNICs, broadcasterOutputsFilter).length > pageSize">
                            <ngb-pagination
                                class="mb-0"
                                [collectionSize]="getOutputNics(broadcasterOutputNICs, broadcasterOutputsFilter).length"
                                [(page)]="page"
                                [pageSize]="pageSize"
                                [maxSize]="2"
                            ></ngb-pagination>
                        </div>
                    </div>
                    <!-- AWS Account -->
                    <div class="form-group" *ngIf="application === 'mediaconnect'">
                        <label for="aws_account_id" [ngClass]="{ 'is-invalid': form.submitted && awsAccount.errors }">
                            {{ "AWS_ACCOUNT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><fa-icon icon="info-circle" [ngbTooltip]="AWSContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #AWSContent>{{ "TOOLTIP.SOURCE_AWS_ACCOUNT" | translate }}</ng-template
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="aws_account_id"
                            name="aws_account_id"
                            required
                            [items]="awsAccounts"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ACCOUNT' | translate }}"
                            [(ngModel)]="source.aws_account_id"
                            (change)="getAWSAccountRegions(source.aws_account_id)"
                            #awsAccount="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && awsAccount.errors }"
                        >
                        </ng-select>
                        <div *ngIf="awsAccount.invalid" class="invalid-feedback">
                            <div *ngIf="awsAccount.errors.required">{{ "AWS_ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Region -->
                    <div class="form-group" *ngIf="application === 'mediaconnect'">
                        <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                            {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="region"
                            name="region"
                            required
                            [items]="awsRegions"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                            [(ngModel)]="source.region"
                            (change)="getAWSMediaConnectFlows(source.aws_account_id, source.region)"
                            bindLabel="name"
                            bindValue="id"
                            #region="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                            [disabled]="!source.aws_account_id || awsRegions.length === 0"
                            [loading]="awsAccountRegionsLoading"
                        >
                        </ng-select>
                        <div *ngIf="region.invalid" class="invalid-feedback">
                            <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- AWS MediaConnect Flow -->
                    <div class="form-group" *ngIf="application === 'mediaconnect'">
                        <label for="mediaconnect_flow_arn" [ngClass]="{ 'is-invalid': form.submitted && mediaconnect_flow_arn.errors }">
                            {{ "AWS_MEDIACONNECT_FLOW" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="mediaconnect_flow_arn"
                            name="mediaconnect_flow_arn"
                            required
                            [items]="mediaconnectFlows"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'AWS_MEDIACONNECT_FLOW' | translate }}"
                            [(ngModel)]="source.mediaconnect_flow_arn"
                            bindLabel="Name"
                            bindValue="FlowArn"
                            #mediaconnect_flow_arn="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && mediaconnect_flow_arn.errors }"
                            [disabled]="!source.region || mediaconnectFlows.length === 0"
                            [loading]="mediaconnectFlowsLoading"
                        >
                        </ng-select>
                        <div *ngIf="mediaconnect_flow_arn.invalid" class="invalid-feedback">
                            <div *ngIf="mediaconnect_flow_arn.errors.required">{{ "AWS_MEDIACONNECT_FLOW" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Max Bitrate -->
                    <div class="form-group" *ngIf="application === 'feeder' && singleMode">
                        <label for="max_bitrate" [ngClass]="{ 'is-invalid': form.submitted && maxBitrate.errors }">
                            {{ "MAX_BITRATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="max_bitrate"
                            name="max_bitrate"
                            required
                            [items]="maxBitrates"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'MAX_BITRATE' | translate }}"
                            [(ngModel)]="source.max_bitrate"
                            bindLabel="name"
                            bindValue="value"
                            #maxBitrate="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && maxBitrate.errors }"
                        >
                        </ng-select>
                        <div *ngIf="maxBitrate.invalid" class="invalid-feedback">
                            <div *ngIf="maxBitrate.errors.required">{{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Host -->
                    <div class="form-group" *ngIf="application === 'zixi_pull'">
                        <label for="remote_host" [ngClass]="{ 'is-invalid': form.submitted && remote_host.errors }">
                            {{ "HOST" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <input
                            type="text"
                            id="remote_host"
                            name="remote_host"
                            placeholder="{{ 'HOST' | translate }}"
                            [(ngModel)]="source.remote_host"
                            required
                            #remote_host="ngModel"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && remote_host.errors }"
                        />
                        <div *ngIf="remote_host.invalid" class="invalid-feedback">
                            <div *ngIf="remote_host.errors.required">{{ "HOST" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Port -->
                    <div class="form-group" *ngIf="application === 'zixi_pull'">
                        <label for="remote_port" [ngClass]="{ 'is-invalid': form.submitted && remote_port.errors }">
                            {{ "PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <input
                            type="number"
                            id="remote_port"
                            name="remote_port"
                            min="1"
                            max="65535"
                            placeholder="{{ 'PORT' | translate }}"
                            [(ngModel)]="source.remote_port"
                            required
                            pattern="^\d+$"
                            #remote_port="ngModel"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && remote_port.errors }"
                        />
                        <div *ngIf="remote_port.invalid" class="invalid-feedback">
                            <div *ngIf="remote_port.errors.required">{{ "PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="remote_port.errors.pattern">{{ "PORT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.</div>
                            <div *ngIf="remote_port.errors.min || remote_port.errors.max">
                                {{ "PORT" | translate }} {{ "MUST_BE_BETWEEN_1_AND_65535" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Stream ID -->
                    <div class="form-group" *ngIf="application === 'zixi_pull'">
                        <label for="pullStreamIdInput" [ngClass]="{ 'is-invalid': form.submitted && pullStreamIdInput.errors }">
                            {{ "STREAM_ID" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <input
                            type="text"
                            id="pullStreamIdInput"
                            name="pullStreamIdInput"
                            placeholder="{{ 'STREAM_ID' | translate }}"
                            [(ngModel)]="pullStreamId"
                            required
                            #pullStreamIdInput="ngModel"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && pullStreamIdInput.errors }"
                        />
                        <div *ngIf="pullStreamIdInput.invalid" class="invalid-feedback">
                            <div *ngIf="pullStreamIdInput.errors.required">{{ "STREAM_ID" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Password -->
                    <div class="form-group" *ngIf="application === 'zixi_pull'">
                        <label for="input_password" [ngClass]="{ 'is-invalid': form.submitted && input_password.errors }">
                            {{ "PASSWORD" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <div class="input-group">
                            <input
                                type="{{ show_password ? 'text' : 'password' }}"
                                autocomplete="cc-csc"
                                id="input_password"
                                name="input_password"
                                placeholder="{{ 'PASSWORD' | translate }}"
                                [(ngModel)]="source.password"
                                #input_password="ngModel"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && input_password.errors }"
                            />
                            <button
                                class="btn btn-outline-primary"
                                (click)="generatePassword()"
                                type="button"
                                title="{{ 'GENERATE_A_RANDOM_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="key"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="show_password = !show_password"
                                type="button"
                                title="{{ 'SHOW_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="eye" *ngIf="!show_password"></fa-icon>
                                <fa-icon icon="eye-slash" *ngIf="show_password"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="copyPassword(source.password)"
                                type="button"
                                title="{{ 'COPY_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="clipboard"></fa-icon>
                            </button>
                        </div>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                            {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <ng-container
                        *ngIf="
                            isEdit &&
                            application === 'other' &&
                            inputMode === 'push' &&
                            (source.broadcaster_cluster_id !== existingSource.broadcaster_cluster_id ||
                                source.target_broadcaster_id !== existingSource.target_broadcaster_id)
                        "
                    >
                        <ngb-alert [dismissible]="false" [type]="'info'" class="mb-2">{{ "HOSTNAME_UPDATE_WARNING" | translate }} </ngb-alert>
                    </ng-container>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && ingestControl.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && ingestControl.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id, true)"
                        ></zx-cluster-select>
                        <div *ngIf="ingestControl.invalid" class="invalid-feedback">
                            <div *ngIf="ingestControl.errors.required">{{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            (change)="getTargetBroadcasterDetails(source.target_broadcaster_id)"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Input NIC Select -->
                    <div class="form-group" *ngIf="application === 'zixi_pull'">
                        <div>
                            <label for="input_nic">{{ "INPUT_NIC" | translate }}</label>
                        </div>
                        <ng-select
                            id="input_nic"
                            name="input_nic"
                            [items]="inputNICs"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            [loading]="targetNICLoading"
                            placeholder="{{ 'SELECT_INPUT_NIC' | translate }}"
                            [(ngModel)]="source.input_nic"
                            [disabled]="source.broadcaster_cluster_id == null || source.target_broadcaster_id == null || source.target_broadcaster_id < 0"
                        >
                        </ng-select>

                        <small *ngIf="source.broadcaster_cluster_id == null || source.target_broadcaster_id == null || source.target_broadcaster_id < 0"
                            >{{ "NIC_INPUT_DISABLED" | translate }}.<br
                        /></small>
                        <small>{{ "VERSION_14_IGNORES" | translate }}.</small>
                    </div>

                    <!-- Latency -->
                    <div class="form-group" *ngIf="application !== 'other'">
                        <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                            >{{ "LATENCY" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="number"
                            id="latency"
                            name="latency"
                            placeholder="{{ 'LATENCY' | translate }}"
                            class="form-control"
                            min="0"
                            pattern="^\d+$"
                            [(ngModel)]="source.latency"
                            [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                            required
                            #latency="ngModel"
                        />
                        <div *ngIf="latency.invalid" class="invalid-feedback">
                            <div *ngIf="latency.errors.required">{{ "LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="latency.errors.pattern">{{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.</div>
                        </div>
                    </div>
                    <!-- Encrypt -->
                    <div class="form-group" *ngIf="application !== 'mediaconnect' && application !== 'other'">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="encrypt" name="encrypt" [(ngModel)]="encrypt" />
                            <label class="form-check-label" for="encrypt"
                                >{{ "ENABLE_ENCRYPTION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                        </div>
                    </div>
                    <!-- Source Password -->
                    <div
                        class="form-group"
                        *ngIf="
                            application !== 'mediaconnect' &&
                            source.broadcaster_cluster_id &&
                            application !== 'zixi_pull' &&
                            (!selectedCluster?.auth_mode ||
                                selectedCluster?.auth_mode === 'zen' ||
                                selectedCluster?.auth_mode === 'password' ||
                                selectedCluster?.auth_mode === 'custom')
                        "
                    >
                        <label for="password">{{ "SOURCE_PASSWORD" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></label>
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                id="password"
                                name="password"
                                [(ngModel)]="source.password"
                                placeholder="{{ 'PASSWORD' | translate }}"
                            />
                            <button
                                class="btn btn-outline-primary"
                                type="button"
                                (click)="generatePassword()"
                                title="{{ 'GENERATE_A_RANDOM_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="key"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="copyPassword(source.password)"
                                type="button"
                                title="{{ 'COPY_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="clipboard"></fa-icon>
                            </button>
                        </div>
                    </div>

                    <!-- Priority client source -->
                    <div class="form-group" *ngIf="application === 'other' && inputMode === 'push'">
                        <label for="priority_source" [ngClass]="{ 'is-invalid': form.submitted && priority_source.errors }"
                            >{{ "PRIORITY_SOURCE_ID" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="text"
                            id="priority_source"
                            name="priority_source"
                            placeholder="{{ 'PRIORITY_SOURCE_ID' | translate }}"
                            class="form-control"
                            [(ngModel)]="source.priority_client_id"
                            [ngClass]="{ 'is-invalid': form.submitted && priority_source.errors }"
                            #priority_source="ngModel"
                        />
                        <small>{{ "PRIORTTY_SOURCE_ID_NOTE" | translate }}.</small><br />
                        <small>{{ "PRIORITY_SOURCE_ID_REQUIREMENT" | translate }}.</small>
                    </div>

                    <!-- PID Mapping Profile -->
                    <div class="form-group">
                        <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                            >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-pid-mapping-profile-select
                            name="pidMappingProfile"
                            [(model)]="source.pid_mapping_profile_id"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                        ></zx-pid-mapping-profile-select>
                        <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.pidMappingProfile?.errors.required">
                                {{ "PID_MAPPING_PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!--Minimize latency -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="minimize_latency"
                                name="minimize_latency"
                                [(ngModel)]="source.minimize_latency"
                            />
                            <label class="form-check-label" for="minimize_latency" translate>MINIMIZE_LATENCY</label>
                        </div>
                        <small *ngIf="application !== 'zixi_pull'">{{ "REQUIRES_BROADCASTER_18_3" | translate }}.</small>
                    </div>
                    <!-- SCTE-35 Log -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="log_scte"
                                name="log_scte"
                                (change)="!source.process_scte_reports ? (source.report_scte_warnings = false) : false"
                                [(ngModel)]="source.process_scte_reports"
                            />
                            <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                        </div>
                        <small>{{ "SCTE_VERSION_AND_CLUSTER_CONFIGURATION_REQUIRED" | translate }}</small>
                    </div>
                    <!-- SCTE-35 Alert -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="alert_scte"
                                name="alert_scte"
                                [disabled]="!source.process_scte_reports"
                                [(ngModel)]="source.report_scte_warnings"
                            />
                            <label class="form-check-label" for="alert_scte">{{ "ENABLE_SCTE_35_ALERTS" | translate }}</label>
                        </div>
                        <small>{{ "REQUIRES_SCTE_35_LOG" | translate }}</small>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields
                        [form]="form"
                        [isEdit]="isEdit"
                        [source]="source"
                        [allowScte35Insert]="inputMode !== 'push'"
                    ></app-source-form-common-fields>
                    <!-- Decryption Key -->
                    <div class="form-group" *ngIf="(inputMode === 'push' && application === 'other') || (inputMode === 'pull' && application === 'zixi_pull')">
                        <label for="decryption_key" [ngClass]="{ 'is-invalid': form.submitted && decryption_key.errors }"
                            >{{ "DECRYPTION" | translate }} {{ "KEY" | translate }}</label
                        >
                        <input
                            type="text"
                            id="decryption_key"
                            name="decryption_key"
                            placeholder="{{ 'DECRYPTION' | translate }} {{ 'KEY' | translate }}"
                            class="form-control"
                            pattern="^[a-fA-F0-9]{32}([a-fA-F0-9]{16})?([a-fA-F0-9]{16})?$"
                            [(ngModel)]="source.decryption_key"
                            [ngClass]="{ 'is-invalid': form.submitted && decryption_key.errors }"
                            #decryption_key="ngModel"
                        />
                        <div *ngIf="decryption_key.invalid" class="invalid-feedback">
                            <div *ngIf="decryption_key.errors.pattern">{{ "DECRYPTION_KEY_PATTERN_ERROR" | translate }}.</div>
                        </div>
                    </div>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showBilling]="true"
                        [showAutopullBilling]="true"
                        [showMTUField]="inputMode === 'pull'"
                        [showAutoMTU]="true"
                        [showInputDeviceBind]="application === 'zixi_pull'"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                        [showFECControls]="
                            (inputMode === 'pull' && application === 'zixi_pull') || (inputMode === 'push' && ['zec', 'broadcaster'].includes(application))
                        "
                        [showFrameThinningControls]="inputMode === 'push' && ['zec', 'broadcaster'].includes(application)"
                    ></app-advanced-section-form>
                    <div *ngIf="savingMultiple">
                        <ul class="list-style-none">
                            <li *ngFor="let newSource of newSources">
                                <fa-icon *ngIf="!newSource._frontData?.finished" icon="clock" class="status-pending"></fa-icon>
                                <fa-icon
                                    *ngIf="newSource._frontData?.finished && !newSource._frontData?.error"
                                    icon="check-circle"
                                    class="status-good"
                                ></fa-icon>
                                <fa-icon *ngIf="newSource._frontData?.error" icon="minus-circle" class="status-error"></fa-icon>
                                {{ newSource.name }}
                                <span *ngIf="!newSource._frontData?.finished">
                                    <span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span
                                    >{{ "PROCESSING" | translate }}...
                                </span>
                                <span *ngIf="newSource._frontData?.finished && !newSource._frontData?.error">&nbsp;-&nbsp;{{ "CREATED" | translate }}</span>
                                <span *ngIf="newSource._frontData?.finished && newSource._frontData?.error"
                                    >&nbsp;-&nbsp;{{ "ERROR" | translate }}: {{ newSource._frontData?.errorMsg }}</span
                                >
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="!savingMultiple">
                        <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    </div>
                    <hr class="mt-0" />

                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-outline-secondary float-start"
                            (click)="back()"
                            *ngIf="!isEdit && !isClone && !savingMultiple"
                            [disabled]="saving"
                        >
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" *ngIf="!savingMultiple" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            *ngIf="!savingMultiple"
                            [disabled]="saving"
                            [ngClass]="{
                                'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid || ingestControl.invalid)
                            }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-secondary" (click)="done()" *ngIf="savingMultiple" [disabled]="saving">
                            {{ "DONE" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
