import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants/constants";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import { GraphsService } from "src/app/services/graphs.service";
import { MediaConnectSource } from "src/app/models/shared";
import { BaseLayout } from "src/app/helpers/base-layout";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "src/app/services/shared.service";
import { MediaConnectSourceDetailsComponent } from "./mc-source-details/mc-source-details.component";
import { MediaConnectSourceStreamComponent } from "./mc-source-stream/mc-source-stream.component";
import { ReactFlowDiagramComponent } from "src/app/components/shared/react-flow-diagram/react-flow-diagram.component";
import { ZxHistoryGraphComponent } from "src/app/components/shared/zx-history-graph/zx-history-graph.component";
import { ZxEventsComponent } from "src/app/components/shared/zx-events/zx-events.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import { MediaConnectSourceContentAnalysisComponent } from "./mc-source-content-analysis/mc-source-content-analysis.component";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export abstract class MediaConnectSourceLayouts extends BaseLayout {
    keys = {
        primaryDetails: [
            //The order is important for the layout
            "SOURCE_THUMBNAIL",
            "AWS_MEDIACONNECT_FLOW",
            "AWS_MEDIALIVE_CHANNEL",
            "PRIORITY",
            "TAGS"
        ],
        secondaryDetails: [
            //The order is important for the layout
            "TYPE",
            "AWS_ACCOUNT",
            "REGION",
            "INPUT",
            "INGEST_PORT",
            "MAX_BITRATE",
            "LATENCY",
            "MAX_SYNC_BUFFER",
            "WHITELIST_CIDR",
            "ALERTING_PROFILE"
        ],
        nonDeletedSecondaryDetails: [
            "TYPE",
            "AWS_ACCOUNT",
            "REGION",
            "INPUT",
            "INGEST_PORT",
            "MAX_BITRATE",
            "LATENCY",
            "MAX_SYNC_BUFFER",
            "WHITELIST_CIDR",
            "ALERTING_PROFILE"
        ],
        widgets: [
            //The order is important for the layout
            "STREAM",
            "DIAGRAM",
            "HISTORY",
            "TR101",
            "DETAILS",
            "EVENTS",
            "NOTES"
            // Changes?
            // Incidents?
        ]
    } as const;

    protected scopeName = "mcSourceDetails";
    constants = Constants;
    abstract source: MediaConnectSource;
    abstract canEdit(source: MediaConnectSource): boolean;
    abstract refreshMediaConnectSourcePromise(): Promise<MediaConnectSource>;
    constructor(
        protected translate: TranslateService,
        protected graphsService: GraphsService,
        protected userService: UsersService,
        protected sharedService: SharedService
    ) {
        super(userService, sharedService, translate);
    }

    protected translatedNames = {
        ...this.translateList(
            [...this.keys.primaryDetails, ...this.keys.secondaryDetails, ...this.keys.widgets],
            this.translate
        )
    };

    protected primaryDetailsDefaultLayout = this.keys.primaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false
    })) satisfies DetailsLayout<typeof this.keys.primaryDetails>[];

    protected sourcePrimaryDetails: Details[] = this.primaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected secondaryDetailsDefaultLayout = this.keys.secondaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false,
        isDeleted: !this.keys.nonDeletedSecondaryDetails.includes(key as any)
    })) satisfies DetailsLayout<typeof this.keys.secondaryDetails>[];

    protected sourceSecondaryDetails: Details[] = this.secondaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected widgetHeadersDefaultLayout = this.keys.widgets.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isSelected: true,
        isHidden: false,
        isExpanded: false
    })) satisfies WidgetHeaderLayout<typeof this.keys.widgets>[];

    protected sourceWidgetHeaders = this.widgetHeadersDefaultLayout.map(layout => ({
        ...layout,
        fa_icon: this.getIconLayoutKey(layout.key)
    })) satisfies WidgetHeader<typeof this.keys.widgets>[];

    protected widgetsDefaultLayout = this.keys.widgets.reduce((obj, key, index) => {
        obj[key] = {
            title: this.translatedNames[key],
            id: this.translatedNames[key],
            key,
            index: index,
            ...this.constants.defaultWidgetLayout
        };
        return obj;
    }, {} as Record<(typeof this.keys.widgets)[number], WidgetLayout>) satisfies Record<
        (typeof this.keys.widgets)[number],
        WidgetLayout
    >;

    protected sourceWidgets: Widget[] = [
        {
            ...this.widgetsDefaultLayout.STREAM,
            component: MediaConnectSourceStreamComponent,
            inputs: {
                source: () => this.source
            }
        },
        {
            ...this.widgetsDefaultLayout.DETAILS,
            component: MediaConnectSourceDetailsComponent,
            inputs: {
                source: () => this.source,
                canEdit: () => this.canEdit(this.source)
            }
        },
        {
            ...this.widgetsDefaultLayout.DIAGRAM,
            component: ReactFlowDiagramComponent,
            inputs: {
                model: () => this.source,
                type: () => "source"
            }
        },
        {
            ...this.widgetsDefaultLayout.TR101,
            component: MediaConnectSourceContentAnalysisComponent,
            inputs: {
                source: () => this.source
            }
        },
        {
            ...this.widgetsDefaultLayout.HISTORY,
            component: ZxHistoryGraphComponent,
            inputs: {
                object: () => ({ mediaconnect_sources: this.source }),
                objectType: () => "mediaconnect_sources",
                graphUrl: () => this.graphsService.custom(this.graphsService.mediaconnectSource(this.source))
            }
        },
        {
            ...this.widgetsDefaultLayout.EVENTS,
            component: ZxEventsComponent,
            inputs: {
                objects: () => ({ mediaconnect_sources: this.source }),
                id: () => this.source.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.NOTES,
            component: ZxAdvancedNotesComponent,
            inputs: {
                model: () => this.source,
                type: () => "mediaconnectSources",
                id: () => this.source.id,
                canEdit: () => this.canEdit(this.source)
            }
        }
    ];

    private getIconLayoutKey(key: (typeof this.keys.widgets)[number]): IconProp {
        switch (key) {
            case "STREAM":
                return "clapperboard-play";
            case "DETAILS":
                return "align-justify";
            case "DIAGRAM":
                return "sitemap";
            case "HISTORY":
                return "chart-line";
            case "EVENTS":
                return "exclamation-triangle";
            case "TR101":
                return "tasks";
            case "NOTES":
                return ["far", "sticky-note"];
            default:
                throw new Error("Unknown title"); //TODO should it throw error or return empty string?
        }
    }
}
