import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { SourcesService } from "../sources.service";
import { SharedService } from "../../../services/shared.service";

import * as _ from "lodash";

import { Source } from "../../../models/shared";
import { TitleService } from "../../../services/title.service";
import { firstValueFrom } from "rxjs";
import { urlBuilder } from "@zixi/shared-utils";
import { Constants } from "../../../constants/constants";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SelectedSourceType } from "src/app/components/shared/multiplex-source-select/multiplex-source-select.component";

@Component({
    selector: "app-source-form-multiplex",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormMultiplexComponent implements OnInit {
    source: Source;
    private sources: Source[];
    private inputSourceId: number;
    private existingSource: Source;
    selectedSources: SelectedSourceType[] = [];

    private action: string;
    loading = true;
    saving = false;

    isEdit = false;
    isClone = false;
    startDisabled = false;
    isMuted = false;

    targetBXsLoading = false;
    targetBXs: { id: number; name: string; type: string; generalStatus: string }[];
    constants = Constants;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private broadcastersService = inject(BroadcastersService);
    private ss = inject(SourcesService);
    private sharedService = inject(SharedService);
    private titleService = inject(TitleService);
    private mixpanelService = inject(MixpanelService);

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const sourceName = params.name;
        const sourceId = urlBuilder.decode(params.sourceId);
        this.action = params.action;

        await firstValueFrom(this.ss.refreshSources(true));
        this.sources = await firstValueFrom(this.ss.sources);

        if (sourceName && sourceId) {
            this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));

            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                await firstValueFrom(this.ss.refreshSource(this.source));
                this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));
            }
            this.existingSource = _.cloneDeep(this.source);
        }
        this.prepForm();
        this.loading = false;
    }

    private prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit" || this.action === "clone") {
                if (this.action === "edit") {
                    this.isEdit = true;
                    this.nameControl.setValue(this.source.name);
                } else {
                    this.isClone = true;
                    this.nameControl.setValue("");
                    this.source.muted = this.source.active_mute ? 1 : 0;
                }
                this.inputSourceId = this.source.source_id;
                if (this.source.broadcaster_cluster_id) this.clusterSelectionChange(this.source.broadcaster_cluster_id);

                if (this.existingSource.multiplexSources) {
                    this.selectedSources = this.existingSource.multiplexSources.map(mpxSource => {
                        return {
                            ...mpxSource,
                            source: this.sources.find(source => source.id === mpxSource.source_id)
                        };
                    });
                }
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.source.type = "multiplex";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    private resetForm() {
        // Source
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);
        this.source.location = {};
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.pid_mapping_profile_id = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.source.target_bitrate = 1000;
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            protocol: "multiplex",
            type: "multiplex",
            name: this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            source_id: this.inputSourceId,
            target_broadcaster_id: this.source.target_broadcaster_id,
            resource_tag_ids: this.tagsControl.value.map(i => i.id),
            alerting_profile_id: this.source.alertingProfile.id,
            location: this.source.location.address,
            is_enabled: !this.startDisabled,
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            webrtc_mode: this.source.webrtc_mode,
            muted: this.isMuted,
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            input_nic: this.source.input_nic && this.source.input_nic !== "" ? this.source.input_nic : null,
            bind_input_device: this.source.bind_input_device ? 1 : 0,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            allow_outputs: !!this.source.allow_outputs,
            outputs_password: !!this.source.allow_outputs ? this.source.outputs_password : undefined,
            content_analysis: this.source.content_analysis,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608_cc: this.source.monitor_cei608_cc ? 1 : 0,
            monitor_cei708_cc: this.source.monitor_cei708_cc ? 1 : 0,
            multiplexSources: this.selectedSources.map(selectedSource => {
                return _.omit(
                    {
                        ...selectedSource,
                        source_id: selectedSource.source.id
                    },
                    ["source", "id", "multiplex_id"]
                );
            }),
            target_bitrate: this.source.target_bitrate,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion,
            drop_inactive_multiplex: this.source.drop_inactive_multiplex
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(
                model,
                {
                    ...this.existingSource,
                    location: this.existingSource.location.address,
                    outputs_password: undefined,
                    allow_outputs: Boolean(this.existingSource.allow_outputs),
                    multiplexSources:
                        this.existingSource?.multiplexSources.map(mpxSource => _.omit(mpxSource, "source")) ?? []
                },
                ["is_enabled", "muted"]
            );
            const isEmptyObject = this.sharedService.isEmptyObject(changedData);
            if (!isEmptyObject) {
                const result = await this.ss.updateSource(this.source, changedData);
                if (result) {
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                }
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(model);
            if (result) {
                this.mixpanelService.sendEvent("create multiplex source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    async clusterSelectionChange(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = [];
        this.targetBXs = await this.getTargetBroadcasters(id);
        this.targetBXsLoading = false;
    }

    private async getTargetBroadcasters(id: number) {
        const clusterBroadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (clusterBroadcasters && clusterBroadcasters.length > 0) {
            return _.map(clusterBroadcasters, broadcaster => {
                return {
                    id: broadcaster.id,
                    name: broadcaster.name,
                    type: "broadcaster",
                    generalStatus: broadcaster.generalStatus
                };
            });
        }
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }
}
